import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import MetaGroup from '../../components/meta-group';

class EULA extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="End User License Agreement | Rossware"
                    metaDescription="This Policy details the agreement between us and the individuals who use our Websites and Applications, who are authorized to use our Services."
                    ogImage=""
                    canonical="eula"
                />
                <div className="py-8 rounded-bottom grey-hero bg-light">
                    <h1 className="text-center">End User License Agreement</h1>
                </div>
                <div className="py-7">
                    <Container>
                        <h3 className="mt-6 mb-3">The Parties agree as follows:</h3>
                        <h3 className="mt-6 mb-3">1. Definitions</h3>
                        <p></p>1.1 The Software. Refers to the particular Licensor-produced software that presently requests acceptance of this EULA (or demands non-use if acceptance is not granted).
                        <p></p>1.2 Confidential Information. The Software (including all of its internal elements), and all information, data, drawings, designs, specifications, documentation, and other items (in any form whatsoever, and including all elements of unique invention, creativity, original insight and composition) which the Licensor may (directly or indirectly) disclose or otherwise make available to the End User during the course of or in anticipation of this EULA.
                        <p></p>1.3 FIeld.  The End User's own internal business operation and purposes.
                        <p></p>1.4 Start Date.  The date and time when this EULA is accepted.

                        <h3 className="mt-6 mb-3">2. Agency and Scope</h3>
                        <p></p>2.1 As the person who clicks to signify acceptance of this EULA, I act as agent with power to bind the End User, whether the End User employs the Software an individual person, proprietorship, partnership, LLC or corporation.
                        <p></p>2.2 Accordingly, by my assent I bind the entire using-organization, along with its agents, principals and employees.

                        <h3 className="mt-6 mb-3">3. Licence</h3>
                        <p></p>3.1 Licence. Subject to the provisions of this EULA, the Licensor grants to the End User a non-transferable, non-sub-licensable, and non-exclusive licence to use, solely in the Field and solely in object code form, the Software.
                        <p></p>3.2 Reservation of rights. Except for the licence explicitly granted by Clause 3.1, the Licensor reserves all its rights.  The End User acknowledges and agrees that the End User is licensed to use the Software only in accordance with the express provisions of this EULA and not further or otherwise.
                        <p></p>3.3 No support, etc. Unless otherwise agreed by the Parties in writing, the Licensor shall have no obligation to upgrade, update, bug-fix, to provide support or maintenance services, or to provide assistance or consultancy services in relation to the Software.

                        <h3 className="mt-6 mb-3">4. Payment</h3>
                        <p></p>4.1 Licence Fee. The End User shall pay for its use of the Software according so such standard rates as the Licensor maintains for such purpose.

                        <h3 className="mt-6 mb-3">5. Confidential Information</h3>
                        <p></p>5.1 Confidentiality obligations. As between the Parties, all Confidential Information belongs to the Licensor. At all times during the term of this EULA and at all times subsequently, the End User shall: (a) maintain all Confidential Information securely and in the strictest confidence and shall respect the Licensor's rights therein; (b) not disclose or otherwise make available any Confidential Information to any third party; and (c) not use any Confidential Information for any purpose except for the purpose of using the Software in accordance with the provisions of this EULA.
                        <p></p>5.2 Exceptions to obligations. The provisions of Clause 5.1 shall not apply to Confidential Information which the End User can demonstrate by reasonable, written evidence: (a) is subsequently disclosed to the End User without any obligations of confidence by a third party who has not derived it directly or indirectly from the Licensor; or (b) is or becomes generally available to the public through no act or default of the End User or its agents, employees, or affiliates.
                        <p></p>5.3 Exceptions Qualification.  Notwithstanding Clause 5.2(b), such commonality of understanding as exists among the large and extended community of Software end users, regarding Confidential Information, shall in no wise be deemed as fitting the definition of Clause 5.2(b).
                        <p></p>5.4 Disclosure to employees, etc. The End User shall assure that all its employees and other personnel who have access to any Confidential Information: (a) shall be made aware of, and subject to the obligations, set out in Clause 5.1; and (b) shall have entered into written undertakings of confidentiality and non-use which are at least as restrictive as Clause 5.1 and which apply to the Confidential Information.
                        <p></p>5.5 Return of Confidential Information. Upon any termination of this EULA for any reason, the End User shall: (a) return to the Licensor any documents and other materials that contain any Confidential Information including all copies made, except for one (1) copy as may be necessary to be retained for the purpose of compliance with any continuing obligations under this EULA; and (b) make no further use, copy, or disclosure thereof.

                        <h3 className="mt-6 mb-3">6. Use, etc.</h3>
                        <p></p>6.1 Restrictions. Except to the extent permitted by applicable mandatory law, the End User shall not: (a) copy or reproduce the Software; (b) merge the Software with any other software; (c) translate, adapt, vary, or modify the Software; or (d) disassemble, decompile, or reverse engineer the Software, or otherwise attempt to derive the source code of the Software.
                        <p></p>6.2 Further restrictions. The End User shall not: (a) provide, disclose, demonstrate, or otherwise make available the Software to any third party; or (b) use the Software to provide any services or training for any third party; or (c) sell, lease, rent, transfer, hire-out, license, sub-license, assign, distribute, publish, charge, pledge, encumber, commercially exploit, or otherwise deal with the Software, or have any software written or developed that is based on or derived from the Software.
                        <p></p>6.3 Obligations. The End User shall at all times: (a) effect and maintain adequate security measures to safeguard the Software from unauthorised access, use, and disclosure; (b) supervise and control access to and use of the Software in accordance with the provisions of this EULA; (c) provide the Licensor from time to time on request with contact details for the person responsible for supervising and controlling such access and use; and (d) ensure that the Software is at all times clearly labelled as the property of the Licensor. The provisions of this Clause 6.3 are without prejudice to the provisions of Clause 7.1.
                        <p></p>6.4 Acknowledgement of Value and Potential Harm. The End User acknowledges the Software contains and manifests a plethora of unique inventions, solutions, methods, structures, compositions and techniques, all of which are the novel creations of the Licensor, and are intellectual property that belongs exclusively to the Licensor.  The End User acknowledges there is great economic value in these creations, and that tremendous harm would accrue to the Licensor if the End User deployed its knowledge of such creations (such knowledge as is, in particular, gained in being the End User) to develop or aid (whether intentionally or unintentionally) in the development of a competing or replacement software system.  The End User further acknowledges tremendous harm would accrue to the Licensor if, via the End User's access to the Software, it allowed unauthorized persons to gain such knowledge and understanding of the Licensor's unique creations as would enable copying, adapting or deriving the same into a competing or substitute software system.
                        <p></p>6.5 Liquidated damages. The End User further acknowledges that to directly quantify the extent of monetary damage in any such instance (as described in Clause 6.4) would be at best impractical.  Accordingly, the End User promises and covenants that if: (a) its actions or incaution lead (or have prior led) to development of a competing or substitute software system; if (b) there are one or more significant elements in the competing or substitute software system that can be reasonably seen to trace its or their origin to the Licensor's unique creations as contained in the Software (whether by direct copying from, adaptation, derivation or inspiration); and if (c) that competing or replacement software system is deployed and used in one or more business operations that might have otherwise been using the Licensor's product, the End User shall pay to the Licensor Liquidated Damages at the rate of $2 per repair job that is managed by the competing or replacement software.  Such liquidated damages shall apply to and be payable by this particular End User, regardless of which particular business or businesses deploy for use of said competing or replacement software.
                        <p></p>6.6 Conspiracy. The End User acknowledges that if it, its principals, employees and/or agents joined with other end users (and/or with their principals, employees and/or agents), and/or if it joined with other parties who are not end users (and/or with their principals, employees and/or agents), and if the joining purpose (or joining result, even had it not been an original purpose) was to cooperate in a scheme to create a competing or replacement software, the probability of grievous harm accruing to the Licensor would be greatly magnified.  Aside from the fact combined resources would result in greater development power, much more sinister would be the fact that multiple conspiring end-user voices would inevitably demand incorporation, within the new and competing or replacement software, of countless among such unique inventions, solutions, methods, structures, compositions and techniques as are contained in the Software (which, likewise, are the intellectual property of the Licensor).  That likelihood is great, in particular, because, as end users, they will have gained intimate appreciation for such immense benefits as inhere in these creations, and will feel loathe to participate in development of or contemplate themselves using any software that does not offer the same (or similar because derived from such structures and innovations as are present in the Software) benefits.
                        <p></p>6.7 Escalated Damages.  In recognition of this enhanced harm dynamic, the End User agrees that, in the event of any such conspiracy (as described in Clause 6.6) having been involved in creation of a competing or replacement software which fits such definition as described in Clause 6.4, the value of liquidated damages as described in Clause 6.5 shall be increased to $3 per repair job that is managed by the competing or replacement software.  Additionally (and as further escalation of damages), the End User promises and covenants to reimburse the Licensor for any and all such costs as may reasonably be incurred in seeking collection of such damages, including all litigations costs and complete attorney's fees.
                        <p></p>6.8 Joint and Several Liability. The End User acknowledges that, in the event of a cooperative venture such as described in Clause 6.6, multiple and different end users may each bear responsibility for the fact Licensor's unique creations were wrongly copied into, adapted or derived from, and/or used as inspiration in a competing or substitute software system.  The End User acknowledges it would be impractical and excessively burdensome, in the event of such multiple end users bearing this responsibility, for the Licensor to proportionately hold each responsible and apportion damages to each according to proportion of responsibility.  Accordingly, the End User agrees that the principle of joint and several liability shall apply, meaning that the Licensor may collect the entirety of the specified liquidated damages from this End User individually, or from any other end users individually, or any portion from any individual end user, the only limitation being that the Licensor's total collection of liquidated damages shall not exceed the amount specified in Clause 6.7.

                        <h3 className="mt-6 mb-3">7. Acknowledgements, disclaimers, etc.</h3>
                        <p></p>7.1 Acknowledgements. The End User acknowledges and agrees that software in general is not error-free and agrees that the existence of any errors in the Software shall not constitute a breach of this EULA by the Licensor.
                        <p></p>7.2 Obligation to correct material errors, etc. If the End User discovers a material error in the functionality of the Software which substantially affects the End User's use of the Software and the End User notifies the Licensor of such material error in writing within 90 days of the date of this EULA, the Licensor shall use reasonable endeavours to correct, by patch or new release (at the Licensor's option), that part of the Software which is affected by such material error.
                        <p></p>7.3 Further support. If the End User requires any further support and/or maintenance services in respect of the Software, the End User shall notify the Licensor in writing and the Parties may discuss the possibility of entering into a support agreement.
                        <p></p>7.4 No such obligation in certain circumstances. The Licensor's obligations under Clause 7.2 shall be excluded in the following circumstances: (a) following wilful damage to the Software or negligence in its use; (b) where the Software has not been used, maintained, and/or stored in accordance with any instructions or recommendations given by the Licensor or in accordance with the provisions of this EULA; (c) where the problem arises from software not supplied by the Licensor; (d) where the Software is used other than in the correct configuration; and/or (e) following any unauthorised use of or modification to the Software.
                        <p></p>7.5 Viruses, etc. The Licensor does not warrant that the Software supplied under this EULA shall be free from all viruses. The End User acknowledges and agrees that it is solely the End User's responsibility to conduct appropriate virus scanning of the Software prior to installation and use.
                        <p></p>7.6 Possible charges. Where a defect in the Software is found upon investigation not to be the Licensor's responsibility, the Licensor reserves the right to charge the End User on a time and materials basis at the Licensor's then current rates for all time, costs, and expenses incurred by the Licensor in consequence of such investigation.
                        <p></p>7.7 No implied warranties, etc. The express undertakings and warranties given by the Licensor in this EULA are in lieu of all other warranties, conditions, terms, undertakings and obligations, whether express or implied by statute, common law, custom, trade usage, course of dealing or in any other way, including any implied warranty of merchantability, satisfactory quality, fitness for any particular purpose. All of these are excluded to the fullest extent permitted by applicable law. Without limiting the scope of the preceding sentences, the Licensor gives no warranty or guarantee that the Software will meet the End User's requirements, that the use of the Software will be un-interrupted, or that any errors and/or defects in the Software can or will be corrected.
                        <p></p>7.8 Indemnity. The End User shall indemnify the Licensor from and against any and all losses, damages, claims, demands, liabilities, costs, and expenses of any nature whatsoever that may be asserted against or suffered by the Licensor and which relate to: (a) any use by the End User of the Software; and/or (b) the development, manufacture, use, supply, or any other dealing in any products or services that were generated from the use of the Software, including any claims based on product liability laws.
                        <p></p>7.9 Limit of liability. The cumulative maximum liability of the Licensor to the End User under or in connection with this EULA, irrespective of the basis of the claim (whether in contract, tort, negligence, or otherwise), shall be limited in accordance with the provisions of this Clause 7.9:
                        <p></p>(a) The Licensor does not seek to exclude or limit liability where such exclusion or limitation is precluded from applicable law, including any liability that may arise in respect of (i) death or personal injury resulting from the Licensor's negligence, or (ii) fraud.
                        <p></p>(b) Subject to the rest of the provisions of this Clause 7.9, the cumulative liability of the Licensor in respect of any and all claims made under or in connection with this EULA shall not exceed an amount that is equal to $100,000.
                        <p></p>(c) Subject to Clause 7.9(a), the Licensor shall in no circumstances be liable for any type of special, indirect or consequential loss, for any loss of profit, revenue, or anticipated saving, lost, incorrect or spoilt data, loss of use, loss of contracts with third parties, loss of business or loss of goodwill, even if such loss was reasonably foreseeable or the Licensor had been advised of the possibility of the same arising.

                        <h3 className="mt-6 mb-3">8. Duration and termination</h3>
                        <p></p>8.1 Commencement and termination. This EULA, and the licence granted hereunder, shall come into effect on the Start Date and shall continue in force unless and until terminated in accordance with Clause 8.2 or Clause 8.3.
                        <p></p>8.2 Termination by either party. Either Party may terminate this EULA, and the licence granted hereunder, at any time by notice in writing to the other Party (the "Other Party"), such termination to take effect as specified in the notice:
                        <p></p>(a) if the Other Party is in material or persistent breach of any provision of this EULA and, in the case of a breach capable of remedy within 10 days, the breach is not remedied within 10 days of the Other Party receiving notice specifying the breach and requiring its remedy; or
                        <p></p>(b) if (i) the Other Party becomes insolvent or unable to pay its debts as and when they become due; or (ii) an order is made or a resolution is passed for the winding up of the Other Party (other than voluntarily for the purpose of solvent amalgamation or reconstruction); or (iii) a liquidator, examiner, receiver, receiver manager, or trustee is appointed in respect of the whole or any part of the Other Party's assets or business; or (iv) the Other Party makes any composition with its creditors; or (v) the Other Party ceases to continue its business; or (vi) as a result of debt and/or maladministration the Other Party takes or suffers any similar or analogous action in any jurisdiction.
                        8.3 Further rights of the Licensor to terminate.
                        <p></p>(a) The Licensor may terminate this EULA, and the licence granted hereunder, at any time by notice in writing to the End User, such termination to take effect as specified in the notice, if the End User fails to pay any amount due under this EULA by the relevant due date.
                        <p></p>(b) In addition, this EULA, and the licence granted hereunder, shall terminate automatically if the Licensor's licence in respect of the Software is terminated for any reason. In such circumstances, the Licensor shall give prompt written notice thereof to the End User.
                        8.4 Consequences of termination. In the event of any termination of this EULA for any reason:
                        <p></p>(a) any licence granted by the Licensor under this EULA shall automatically terminate without further notice, and the End User shall make no further use of, or carry out any other activity in relation to, the Software; and
                        <p></p>(b) any instalments of the Licence Fee due to be paid after the date of termination shall forthwith become due and payable by the End User, and the Licensor shall be under no obligation to reimburse the whole or any part of the Licence Fee.
                        8.5 Accrued rights. Termination of this EULA for any reason shall not affect the rights and obligations of the Parties accrued before termination, including any right to claim damages. The rights and obligations of the Parties set out in Sections 5 and 6 shall survive any termination of this EULA for any reason.

                        <h3 className="mt-6 mb-3">9. General</h3>
                        <p></p>9.1 Amendments. This EULA may only be amended in writing signed by duly authorised representatives of the Licensor and the End User.
                        <p></p>9.2 Invalid clauses. If any provision or part of this EULA is held to be invalid, amendments to this EULA may be made by the addition or deletion of wording as appropriate to remove the invalid part or provision but otherwise retain the provision and the other provisions of this EULA to the maximum extent permissible under applicable law.
                        <p></p>9.3 No agency. Nothing in this EULA shall create, evidence, or imply any agency, partnership, or joint venture between the Parties. Neither Party shall act or describe itself as the agent of the other, nor shall it make or represent that it has authority to make any commitments on the other's behalf.
                        <p></p>9.4 Interpretation. In this EULA:
                        <p></p>(a) references to persons include incorporated and unincorporated persons and references to the singular include the plural and vice versa;
                        <p></p>(b) references to Clauses and Exhibits mean clauses of, and exhibits to, this EULA;
                        <p></p>(c) references in this EULA to termination include termination by expiry; and
                        <p></p>(d) where the word "including" is used it means "including without limitation".
                        <param></param>9.5 Governing law and jurisdiction. The validity, construction, and performance of this EULA shall be governed by and construed in accordance with the laws of the State of Washington and any dispute shall be resolved within courts (State or Federal) resident to that State.
                        <p></p>9.6 No use of names, etc. Neither Party shall use the names, marks, trade names, trademarks, crests, logos, or registered images of the other Party without that Party's prior written consent.
                        <p></p>9.7 Entire agreement. This EULA, including the attached Exhibits, sets out the entire agreement between the Parties relating to its subject matter and supersedes all prior oral and written agreements, arrangements, or understandings between them relating to such subject matter.
                    </Container>
                </div>
            </div>
        );
    }
}

export default EULA;