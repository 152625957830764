import React, { Component } from 'react';
import MetaGroup from '../../components/meta-group';
import DarkCallToAction from '../../components/dark-call-to-action';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

class SDDealer extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Merchandise sales - Rossware"
                    metaDescription="The premier software for any field service business. ServiceDesk is not a mass-market software for mere auxiliary functions, it is a customized operations software, providing the automated nuts and bolts for what you really do, as a service call-oriented office, in delivering service to your customers."
                    ogImage="%PUBLIC_URL%/logo512.png"
                    canonical="features/call-management"
                />
                <div className="py-6">
                    <Container className="container-sm">
                        <div className="text-center">
                            <h1 className="h2">Sell serialized merchandise</h1>
                            <p className="lead pb-4">If besides servicing, you sell serialized merchandise of any kind, this standlone app is for you.</p>
                            <div>
                                <Link className="btn btn-primary btn-icon-right px-5" to="/contact">Contact sales <FaArrowRight /></Link>
                            </div>
                        </div>
                    </Container>
                </div>
                <div className="bg-white border-top border-bottom">
                    <div className="py-7 container text-center">
                        <h3 className="mb-4">Simple and effective</h3>
                        <p>SD-Dealer is a simple, easy to use, and highly effective software built to maintain serialized inventory and make over-the-counter sales. A common use case is if you have makes sales in addition to servicing appliances.</p>
                        <img src="https://cdn.buttercms.com/VKhecoqySFmGHy8ie1XZ" className="w-100 shadow border" alt="SD-RevenueBuilder app helps you maintain service contracts and maintenance agreements." />
                    </div>
                </div>
                <DarkCallToAction title="Just $25/mo" subtitle="Contact our sales department to get started today!" link="/contact" linkText="Contact" />
            </div>
        );
    }
}

export default SDDealer;