import React from 'react'
import { Helmet } from 'react-helmet';
import ReactTooltip from 'react-tooltip';
import PricingSchedule from '../../components/pricing-schedule';

export default class StandalonePricing extends React.Component {


	render() {

		return (
			<div className="pb-6">
				<Helmet>
					<title>Rossware | Pricing Schedule</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Rossware pricing - ServiceDesk pricing scales with your business. ServiceDesk addons are ala carte." />
					<meta property="og:title" content="Pricing - Rossware Software, LLC." />
					<meta property="og:image" href="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<ReactTooltip />
				<div className="pt-lg-6 pt-md-4">
					<div className="container-md">
						<h1 className="mb-5">Pricing schedule</h1>
					</div>
				</div>
				<PricingSchedule isStandalone />
			</div>
		)
	}
}