import React from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { MdPhone, MdEmail, MdCast } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MetaGroup from '../components/meta-group';
import { FaArrowRight } from 'react-icons/fa';
import Faq from '../components/faq';
import { submitContactForm } from '../actions/forms';

export default class Contact extends React.Component {
	state = {
		to: 'support@rossware.net',
		from: '',
		subject: '',
		body: '',
		sendSuccess: false,
		captchaGood: false,
	}

	onChangeEmail = (event) => {
		console.log(this.state)
		this.setState({ from: event.target.value });
	}

	onChangeSubject = (event) => {
		if (event.target.value === 'Contacting RosswarePay') {
			this.setState({ to: 'pay@rossware.net' });
		}
		this.setState({ subject: event.target.value });
	}

	onChangeBody = (event) => {
		this.setState({ body: event.target.value });
	}

	onChangeCaptcha = (event) => {
		console.log(event)
		this.setState({ captchaGood: event });
	}

	async handleSubmit() {
		this.setState({ sendSuccess: true });
		const { to, from, subject, body } = this.state;
		await submitContactForm({ to, from, subject, body });
	}

	render() {
		function isEmailValid(email) {
			const value = email.toLowerCase();
			if (!value) { return false }
			if (value.match(/.+?@.+?\..+/)) { return true } else { return false }
		}
		const { from, subject, body, captchaGood, sendSuccess } = this.state;
		var disabled = true;
		if (subject && body && captchaGood && isEmailValid(from)) {
			disabled = false;
		}
		return (
			<div>
				<MetaGroup
					title="Contact us - Rossware Software, LLC."
					metaDescription="Reach out to us, we're here to help you. You can contact us by phone, email, or live chat."
					ogImage="%PUBLIC_URL%/logo512.png"
					canonical="contact"
				/>
				<section>
					<div className="container">
						<div>
							<h1 className="mb-3 text-center">Contact our awesome team</h1>
							<p className="text-center mb-5">Mon-Fri 6AM-5PM Pacific</p>
						</div>
						<div className="grid-3-col">
							<div className="card bg-secondary">
								<div className="card-body">
									<h3 className="h3 text-white">Software Support</h3>
									<a href="mailto:hello@rossware.net" className="d-block text-light mt-3"><MdEmail /> hello@rossware.com</a>
									<a href="tel:8003534101" className="d-block text-light mt-2"><MdPhone /> 800-353-4101</a>
								</div>
							</div>
							<div className="card bg-light-tone">
								<div className="card-body">
									<h4 className="h4">Payment device, chargeback & funding support</h4>
									<a href="mailto:payments.support@fullsteam.com" className="d-block mt-3"><MdEmail /> payments.support@fullsteam.com</a>
									<hr />
									<h4 className="h4">Integrated payments support</h4>
									<a href="mailto:pay@rossware.net" className="d-block mt-3"><MdEmail /> pay@rossware.com</a>
									<a href="tel:888-351-0170" className="d-block mt-2"><MdPhone /> 888-351-0170</a>
								</div>
							</div>
							<div className="card bg-light-tone">
								<div className="card-body">
									<h3 className="h3">Live Remote Support</h3>
									<Link to="/connect" className="d-block mt-3"><MdCast /> Connect to our team</Link>
								</div>
							</div>
						</div>
						<div className=" mt-5">
							<div className="">
								{sendSuccess &&
									<Alert variant="success" className="py-4">
										<Alert.Heading>Thanks for reaching out to us!</Alert.Heading>
										<hr />
										<div>Please allow us some time to respond to your email.</div>
									</Alert>
								}
								{!sendSuccess &&
									<Form className="text-left">
										<h3 className="h4 mb-3">Send us a message</h3>
										<div className="form-group">
											<div className="form-label">Subject</div>
											<select className="custom-select" defaultValue="0" onChange={this.onChangeSubject}>
												<option value="0" disabled>How can we help you?</option>
												<option value="Contacting RosswarePay">RosswarePay</option>
												<option value="Contacting support">Product support</option>
												<option value="Contacting sales">Sales</option>
												<option value="Contacting RosswarePay">RosswarePay</option>
												<option value="I want to schedule a demo">Schedule a demo</option>
												<option value="Contacting other">Other</option>
											</select>
										</div>
										<div className="form-group">
											<div className="form-label">Email address</div>
											<Form.Control type="email" onChange={this.onChangeEmail} placeholder="you@company.com" />
										</div>

										<Form.Group controlId="exampleForm.ControlTextarea1">
											<div className="form-label">Message</div>
											<Form.Control as="textarea" rows="3" onChange={this.onChangeBody} />
										</Form.Group>
										<div className="float-left mb-3">
											<ReCAPTCHA
												sitekey="6LcjVFsUAAAAAAT0mrnR8ONYHMxXbX5a37OGH8LU"
												onChange={this.onChangeCaptcha}
											/>
										</div>
										<Button disabled={disabled} className="btn-icon-right" onClick={() => this.handleSubmit()}>Send message <FaArrowRight /></Button>
									</Form>
								}
							</div>
						</div>
					</div>
				</section>

				<section>

				</section>
				<Faq />
			</div>
		)
	}
}