import React, { Component } from 'react';
import Faq from '../../components/faq';
import MetaGroup from '../../components/meta-group';

class FAQ extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Frequently Asked Questions | Rossware"
                    metaDescription="These are the most frequently asked questions we get about our software, Servicedesk."
                    ogImage=""
                    canonical="faq"
                />
                <div className="py-6">
                    <h1 className="text-center">Frequently Asked Questions</h1>
                    <Faq /> 
                </div>
            </div>
        );
    }
}

export default FAQ;