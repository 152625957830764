import React, { PureComponent } from 'react'

class ServerTerms extends PureComponent {
    render() {
        return (
            <div>
                <h3>Rossware Server Hosting as a Service Agreement</h3>
                <p>This Agreement is a contract between you and Fullsteam Software Holdings LLC (Rossware Software). and applies to the use of the SD Server Hosting service and any related products and services (collectively the "Service").</p>
                <p>In this Agreement, "you" or "your" refers to any person or entity using the Service ("Users"). Unless otherwise stated, "Rossware", "we" and "our" will refer collectively to Fullsteam Software Holdings LLC (Rossware Software). and its affiliates, directors, employees, agents and contractors.</p>
                <p>By signing this Agreement, or logging into and using the provided service, you agree to the terms and conditions of this Agreement. You further agree that this Agreement forms a legally binding contract between you and Rossware Computing, Inc, and that this Agreement constitutes "a writing signed by "You" under any applicable law or regulation.</p>

                <h3>Financial Arrangements</h3>
                <ol>
                    <li>You agree to a 6 month(s) contract, beginning upon the initial transaction.</li>
                    <li>This agreement will automatically renew for successive 6 month(s) periods unless canceled in writing prior to the renewal date. </li>
                    <li>Cancellation within the first 3 months will result in a $300 early-termination fee.</li>
                    <li>Nonpayment will result in revoked access to the server and all data stored upon its drives.</li>
                </ol>

                <h3>Services Provided</h3>
                <ol>
                    <li>Rossware will provide a sub domain (i.e. “you.rosswarehosting.com”) to facilitate your access to the services.</li>
                    <li>Rossware will provide a fully managed service solution to you. This will include managing all aspects of the server software, excluding updates to ServiceDesk software and all subscribed utilities.</li>
                    <li>Rossware will not provide support for any additional software that is not installed and approved of by Rossware. This includes programs such as 3CX, QuickBooks, etc.</li>
                    <li>Rossware makes no warranties or representation of any kind, whether expressed or implied for the Service it is providing. Rossware also disclaims any warranty of merchantability or fitness for particular purposes and will not be responsible for any damages that may be suffered by the Client, including loss of data resulting from delays, non-deliveries or service interruptions by any cause or errors or omissions of the Client. Rossware expressly limits its damages to the Client for any non-accessibility time or other down time to the pro rata monthly charge during the system unavailability. Rossware specifically denies any responsibilities for any damages arising as a consequence of such unavailability. </li>
                    <li>You agree that your use of RSS will constitute a single tenancy. You agree to not sub-let the service to (or share use with) another company if owned by (or if shares therein are held by) another person, nor to sub-let to (nor allow use by) other human persons, except those directly employed by you and when engaged in pursuing your direct business purposes. To state it otherwise, all use shall be under a single common (and unified) ownership, and exclusively for the business purposes of that ownership. This does not prohibit a single owning-entity from running multiple businesses within one and the same RSS server.</li>
                    <li>Although Microsoft Office is preinstalled on the RSS Servers, you will still need to obtain an E3 license to activate it to be fully functional. Although other versions of Microsoft Office may work within the RSS Server environment, the Enterprise 3 licensed version is the only version we can assure will work within the RSS Servers. Please see <a href="https://resources.rossware.com/docs/provisioning-for-microsoft-office-on-rss-servers">here</a> for more information.</li>
                </ol>

                <h3>Printers (specifically Dymo Label Writers)</h3>
                <p>Although a vast majority of printer setups work flawlessly with printing from within the RSS Server environment to the local environment where the printer is located, there may be some impediments that prevent desirable printing expectations. Mainly if there is already an existing fault within your local environment between the printer and the local computer, it will of course not be successful when attempting to print from within the RSS environment.</p>
                <p>Dymo specifically states on its <a href="https://www.dymo.com/support?cfid=software-faqs">website</a> that it does not support Windows Terminal Service (Remote Desktop). Most users are able get them to work with or without workarounds with no issues. However, due to the lack of direct support for Windows Terminal Services we cannot be made responsible for the trouble shooting of Dymo Label Writers experiencing issues while trying to print from within the RSS Servers.</p>

                <h3>Internet Etiquette</h3>
                <p>The network resources of Rossware may not be used to impersonate another person or misrepresent authorization to act on behalf of others or Rossware staff. All messages transmitted via Rossware’s network should correctly identify the sender. User may not alter the attribution or origin in electronic mail messages or posting. Users must not attempt to undermine the security or integrity of computing systems or network and must not attempt to gain unauthorized access.</p>
                <p>In addition, the use of unsolicited bulk email (SPAM) for advertising, promotion or other purposes is forbidden. Such practices will not be tolerated and are grounds for contract termination. This applies both to email sent from any Rossware server and to email sent from other servers making reference to a website on a Rossware server. </p>

                <h3>Other Conditions of Use</h3>
                <p>The full implementation of the RSS Key security service is a strict requirement while accessing your RSS server. This service works to keep you and your server safe by limiting which IP addresses may connect. More information can be found <a href="https://resources.rossware.com/docs/rss-key">here.</a></p>
                <p>Modifications of Windows group policy objects without written authorization by Rossware are prohibited. Modification of these objects can have unintended consequences and make support of your server impossible. Rossware is not responsible for and cannot provide support for a server which has had its group policy objects improperly modified. If a modification is required, it shall only be made by Rossware staff.</p>
                <p>It is the responsibility of the RSS Server lessee to ensure the RSS Server is rebooted on a regular basis (preferably, at least once a month) to ensure that all needed updates and security patches are applied. Routine RSS Server reboots also ensure optimal performance and reduced time to recover from a backup if necessary. Application of updates during reboot can take up to 2 hours, so it is recommended reboots are done outside business hours to ensure no operation downtime.</p>
                <h3>Termination </h3>
                <p>This agreement may be terminated by either party. Notwithstanding the above, Rossware may terminate Service under this agreement at any time, without penalty.</p>

                <h3>Limited liability </h3>
                <ol>
                    <li>Client expressly agrees that use of Rossware servers is at Client's sole risk. Neither Rossware, its employees, affiliates, agents, third party information providers, merchants, licensers or the like, warrant that the Rossware service will not be interrupted or error free; nor do they make any warranty as to the results that may be obtained from the use of the server service or as to the accuracy, reliability or content of any information service or merchandise contained in or provided through the Service, unless otherwise expressly stated in this agreement. </li>
                    <li>UNDER NO CIRCUMSTANCES, SHALL ROSSWARE, ITS OFFICES, AGENTS OR ANY ONE ELSE INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING ROSSWARE SERVER SERVICES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF OR INABILITY TO USE THE ROSSWARE SERVER SERVICE, OR THAT RESULTS FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT LIMITED TO ACTS OF GOD OR COMMUNICATION FAILURE. CLIENT HEREBY ACKNOWLEDGES THAT THIS PARAGRAPH SHALL APPLY TO ALL CONTENT ON ROSSWARE SERVER SERVICE. </li>
                </ol>

                <h3>Lawful purpose </h3>
                <p>Client may only use Rossware servers for lawful purposes. Transmission of any material in violation of any Federal, State or local regulation is prohibited. This includes, but is not limited to material legally judged to be threatening or obscene, pornographic, profane, or material protected by trade secrets. </p>
                <p>Furthermore, Rossware expressly forbids anyone from using Rossware servers for the propagation, distribution, housing, processing, storing, or otherwise handling in any way lewd, obscene, or pornographic material, or any other material which we deem to be objectionable, including, but not limited to, pornography, and any and all materials of an adult nature.</p>

                <h3>Compatibility with Apple Operating Systems</h3>
                <p>Though you may find such configurations readily workable, Rossware provides no support for Apple or iOS-based RDP configurations. If you choose such routes of operation, all configuration and troubleshooting issues will be on your side solely.</p>

                <h3>Indemnification </h3>
                <p>Client agrees that it shall defend, indemnify, save and hold Rossware harmless from any and all demands, liabilities, losses, costs and claims, including reasonable attorney fees, asserted against Rossware Computing, Inc, its agents, its customers, servants, officers and employees, that may arise or result from any Service provided or performed or agreed to be performed or any product sold by Client, its agents, employees or assigns. </p>

                <h3>Contract Revisions </h3>
                <p>Revisions to this contract will be applicable to previous contracts. Revisions will be considered agreed to by the Client on renewal of Fullsteam Software Holdings LLC (Rossware Software). Services as specified in Section 1, Financial Arrangements. </p>

                <h3>Entire Agreement and Severability </h3>
                <p>This instrument constitutes the entire agreement between the parties, and represents the complete and entire understanding of the parties with respect to the subject matter of this agreement. This instrument supersedes any other agreement or understanding between the parties, whether written or oral. In the event that any term or provision of this instrument is held by a court of competent jurisdiction to be unenforceable, then the remaining provisions of this instrument and the agreement which it evidences shall remain in full force and effect. </p>

            </div>
        );
    }
}

export default ServerTerms