import React from 'react';
import { Link } from 'react-router-dom';

export default class DarkCallToAction extends React.Component {
	render() {
		return (
			<div className="">
				<div className="container"><div className="border-top"></div></div>
				<div className="container py-7 text-center">
					<h3 className="h2 mr-3">{this.props.title}</h3>
					<p className="lead">{this.props.subtitle}</p>
					{!this.props.linkExternal && <Link to={this.props.link} className="btn btn-primary">{this.props.linkText}</Link>}
					{this.props.linkExternal && <a href={this.props.link} className="btn btn-primary">{this.props.linkText}</a>}
				</div>
			</div>
		)
	}
}