import React, { Component } from 'react';
import DarkCallToAction from '../../components/dark-call-to-action';
import { MdError } from 'react-icons/md';
import { Helmet } from 'react-helmet';

class NotFound extends Component {
	render() {
		return (
			<div>
				<Helmet>
					<meta name="fragment" content="!" />
					<title>Nothing here! - Rossware Software, LLC.</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Looks like you found a page that doesn't exist. Let us know if this is a mistake" />
					<meta property="og:title" content="Nothing here! - Rossware Software, LLC." />
					<meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<div className="container-sm text-center py-9">
					<h1 className="display-1 text-muted"><MdError /></h1>
					<h1 className="text-muted">PAGE NOT FOUND</h1>
					<div className="text-muted">The page you're looking for seems to be missing or you tried going somewhere that doesn't exist. If you think there's been a mistake please try again or contact our support department.</div>
				</div>
				<DarkCallToAction title="Have you checked out our resources?" subtitle="Our resources site has tons of manuals, tutorials, video how-tos and more." linkExternal link="https://resources.rossware.com" linkText="Visit Resources" />
			</div>
		);
	}
}

export default NotFound;