import React from 'react';
import DarkCallToAction from '../../components/dark-call-to-action';
import SdmHero from '../../assets/img/sdmobile-hero.png';
import SdMobileTablet1 from '../../assets/svg/sdmobile-tablet1.svg';
import SdMobileTablet2 from '../../assets/svg/sdmobile-tablet2.svg';
import TriagePicture from '../../assets/images/triage.jpg';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

export default class SdMobile extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<title>Field Service Technician Mobile App - SD-Mobile</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="SD-Mobile is a powerful app designed to fulfill any need your service technicians may have in the field. Job information is automatically uploaded from ServiceDesk to the tech's mobile device." />
					<meta property="og:title" content="Field Service Technician Mobile App - SD-Mobile" />
					<meta property="og:image" content="path/to/image.jpg" />
				</Helmet>
				<div className="py-6">
						<Container className="">
								<div className="grid-2-col md-gap">
										<div className="d-flex flex-column justify-content-center align-items-left">
												<div className="pre-text">Technician mobile app</div>
												<h1 className="h2">Make your techs superheroes</h1>
												<p className="pb-4">This powerful mobile app is designed to fulfill every need your service technicians may have in the field.</p>
												<div>
														<Link className="btn btn-primary btn-icon-right mr-5" to="/sd-order">Sign up <FaArrowRight /></Link>
														<Link className="btn btn-light btn-icon-right" to="/contact">Request demo <FaArrowRight /></Link>
												</div>
										</div>
										<img src={SdmHero} className="sd-mobile-hero" alt="Appliance Repair software" />
								</div>
						</Container>
				</div>
				<div className="bg-white">
					<div className="pt-7 pb-4 container">
						<h3 className="text-center mb-4">Live connection to the office</h3>
						<p className="">You've seen the UPS man do it, the FedEx man, even the DHL man. They all have a portable electronic device that tethers them (information-wise) to headquarters. Without any separate human effort, it keeps each mobile-person informed of what he needs to know (where to go, what to do, etc.), while also keeping headquarters informed of what's happening with the mobile person.</p>
						<img src={SdMobileTablet1} className="" alt="SD-RevenueBuilder app helps you maintain service contracts and maintenance agreements." />
					</div>
				</div>
				<div className="py-7 bg-white border-bottom">
					<Container>
						<div className="grid-2-col mb-6 md-gap">
							<div className="h-100 d-flex flex-column justify-content-center">
								<h3>The digital assistant for your techs</h3>
								<p className="text-muted">When a job is assigned to a technician, it populates the job list on his or her device, and provides every bit of relevant information. Any work they do on that job will be sent back to the office!</p>
								<p className="text-muted">Get mapped guidance to jobs, clock in, record post visit reports, create tickets, return-visit appointments, charge customers in the field, and more. This app has it all.</p>
							</div>
							<img src={SdMobileTablet2} className="w-100" alt="SD-Mobile app increases technician effectiveness." />
						</div>
						<div className="grid-2-col md-gap">
							<img src={TriagePicture} className="w-100" alt="Triage feature allows techs to plan for future jobs." />
							<div className="h-100 d-flex flex-column justify-content-center">
								<h3>View schedules, job statuses, and triage parts.</h3>
								<p className="text-muted">Give techs permissions to view jobs assigned to them in the future and even triage parts for them. With an auto-lookup of part numbers, and MyPartsHelp Diagnostic tool that recommends the parts based on model and symptom, you can triage parts for jobs and be ready.</p>
							</div>
						</div>
					</Container>
				</div>
				<DarkCallToAction title="Check out our flexible pricing" link="/pricing" linkText="Contact to get started" />
				</div>
		)
	}
}