import React from 'react'
import { Button } from 'react-bootstrap';
import DarkCallToAction from '../../components/dark-call-to-action';
import SuccessAlert from '../../components/alert-success';
import MetaGroup from '../../components/meta-group';
import Loading from '../../components/loading';

export default class Connect extends React.Component {
	state = {
		sixDigitCode: '',
		name: '',
		company: '',
		question: '',
		onThePhone: false,
		notOnThePhone: false,
		showMenu: true,
		showThankYou: false
	}

	getIssues = async () => {
		try {
			const results = await fetch(`${process.env.REACT_APP_ROSSWARE_API_BASE_URL}/v3/backend/getbeyondtrustissues?backend_key=${process.env.REACT_APP_BT_BACKEND_KEY}`);
			const resultsJson = await results.json();
			return resultsJson;
		} catch (error) {
			console.log(`renderIssues failed with error: ${error}`);
		}
	}

	showOnThePhone = () => {
		this.setState({
			onThePhone: true,
			notOnThePhone: false,
			showMenu: false
		});
	}

	showNotOnThePhone = async () => {
		this.setState({ loading: true })
		const issues = await this.getIssues();
		this.setState({
			onThePhone: false,
			notOnThePhone: true,
			showMenu: false,
			issues: issues,
			loading: false,
		});
	}

	handleOnThePhoneSubmit = () => {
		this.setState({
			showThankYou: true
		})
	}

	showThankYou = () => {
		this.setState({ showThankYou: true });
	}

	renderOnThePhone() {
		return (
			<div>
				<h1 className="mb-4 h2">Please enter the code provided by us</h1>
				<form className="mb-3" action="https://remotesupport.rossware.com/api/start_session" method="GET">
					<input type="hidden" name="tracking0" className="form-control" />
					<input type="hidden" name="language" className="form-control" />
					<input type="hidden" name="hostederrorhandling" className="form-control" />
					<div className="d-flex align-items-center">
						<input type="text" name="short_key" className="form-control mr-3" placeholder="Your seven-digit code" />
						<button type="submit" className="btn btn-primary" onClick={()=>this.showThankYou()}>Connect</button>
					</div>
				</form>
				{this.state.showThankYou && <SuccessAlert message="Thank you for connecting. A download should start soon to connect to your support technician. If it does not start, please let them know."/>}
			</div>
		)
	}

	renderIssuesMenu () {
		const { issues } = this.state;
		return issues.map((issue) => {
			return <option key={ `${issue.issue_code}key` } value={ issue.issue_code }>{ issue.issue_name }</option>
		})
	}

	renderNotOnThePhone() {
		return (
			<div>
				<h1 className="mb-4">Please fill out the information below</h1>
				<form className="mb-3" method="GET" action="https://remotesupport.rossware.com/api/start_session">
					<input type="hidden" name="language" className="form-control" />
					<input type="hidden" name="issue_menu" className="form-control" value="1" />
					<div className="grid-2-col">
						<div className="form-group">
							<label>Your name</label>
							<input type="text" name="customer.name" className="form-control" placeholder="Your name"/>
						</div>
						<div className="form-group">
							<label>Company name</label>
							<input type="text" name="customer.company" className="form-control" placeholder="Company name"/>
						</div>
					</div>
					<div className="form-group">
						<label>Topic</label>
						<select name="codeName" className="form-control" defaultValue="default">
							<option key="default" value="default">Select one</option>
							{ this.renderIssuesMenu() }
						</select>
					</div>
					<div className="form-group">
						<label>Your question</label>
						<textarea name="customer.details" className="form-control" placeholder="What can we help you with today?"></textarea>
					</div>
					<button type="submit" className="btn btn-primary" onClick={() => this.showThankYou()}>Connect now</button>
				</form>
				{this.state.showThankYou && <SuccessAlert message="Thank you for connecting. A download should start soon to connect to your support technician. If it does not start, please call or email Rossware support." />}
			</div>
		)
	}

	renderMenu() {
		const { loading } = this.state;
		if (loading) {
			return <Loading />
		}
		return (
			<div>
				<h1 className="text-center mb-4">Use one of these methods to connect for support</h1>
				<div className="btn-group btn-block" role="group">
					<Button className="py-5 btn-lg" variant="outline-primary" onClick={() => this.showOnThePhone()}>I'm already on the phone with support</Button>
					<Button className="py-5 btn-lg" variant="outline-primary" onClick={() => this.showNotOnThePhone()}>I'm not on the phone with support</Button>
				</div>
			</div>
		)
	}

	render() {
		const { onThePhone, notOnThePhone, showMenu } = this.state;
		return (
			<div>
				<MetaGroup
					title="Connect for support - ServiceDesk"
					metaDescription="Connect to our Rossware support team for immediate chat and remote assistance. Our team will be with you shortly."
					ogImage="%PUBLIC_URL%/logo512.png"
				/>
				<div className="container-sm py-7">
					{showMenu && this.renderMenu()}
					{onThePhone && this.renderOnThePhone()}
					{notOnThePhone && this.renderNotOnThePhone()}
				</div>
				<DarkCallToAction title="Have you checked out our resources?" subtitle="Our resources site has tons of manuals, tutorials, video how-tos and more." linkExternal link="https://resources.rossware.com" linkText="Visit Resources" />
			</div>

		)
	}
}
