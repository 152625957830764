import React from 'react'
import Alert from 'react-bootstrap/Alert'

class SuccessAlert extends React.Component {
	render() {
		return (
			<Alert className="my-2" variant="success">
				{this.props.message}
			</Alert>
		)
	}
}
export default SuccessAlert