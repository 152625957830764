import React from 'react';
import { ListGroup, Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import MetaGroup from '../../components/meta-group';
import { FaArrowRight, FaCheck } from 'react-icons/fa';
import { Fragment } from 'react';
import GetDemo from '../../components/CTAs/GetDemo';
import SchedulerImage from '../../assets/images/cyberoffice/cyberoffice-schedule.gif';
import ConfirmImage from '../../assets/images/cyberoffice/confirm.gif';
import SurveyImage from '../../assets/images/cyberoffice/survey-example-01.png';
import AutomationsImage from '../../assets/images/cyberoffice/automations.png';

export default class CyberOffice extends React.Component {
	state = {
		hideExtraPricing: true
	}

	toggleExtraPricing = () => {
		const { hideExtraPricing } = this.state;
		if (hideExtraPricing) {
			this.setState({ hideExtraPricing: false });
		} else {
			this.setState({ hideExtraPricing: true });
		}
	}

	render() {
		const { hideExtraPricing } = this.state;
		return (
			<div>
				<MetaGroup
					title="Online Scheduling and Automations | Rossware"
					metaDescription="A suite of online interactive services to automate communication between office and consumer"
					ogImage="%PUBLIC_URL%/logo512.png"
					canonical="features/sd-cyberoffice"
				/>
				<div className="py-6">
					<Container className="">
						<div className="grid-2-col lg-gap">
							<div className="d-flex flex-column justify-content-center align-items-left">
								<div className="pre-text">sd-cyberoffice addon</div>
								<h1 className="h2">Online scheduling and automation</h1>
								<p className="lead text-muted">Modern times calls for modern measures. These days, the overwhelming majority of consumers prefer to schedule online compared to scheduling via phone or other means.</p>
								<p className="lead text-muted pb-4">Impress your customers with slick and modern services like <a href="#online-booking" className="badge-link">online scheduling</a>, <a href="#features" className="badge-link">automated communications</a>, <a href="#features" className="badge-link">technician tracking</a>, <a href="#features" className="badge-link">job tracking</a>, and <a href="#features" className="badge-link">effective customer surveys</a>.</p>
								<div>
									<Link className="btn btn-primary btn-icon-right mr-3" to="/contact">Contact sales <FaArrowRight /></Link>
									<Link className="btn btn-link btn-icon-right" to="/demo">Request demo <FaArrowRight /></Link>
								</div>
							</div>
							<div className="position-relative d-flex align-items-center">
								<img src={SchedulerImage} alt="ServiceDesk on an Laptop" width="100%" className="py-4 oversize-image border rounded shadow-hard crisp-image" />
							</div>

						</div>
					</Container>
				</div>
				<div className="" id="features">
					<Container className="py-7 container">
						<div className="grid-3-col">
							<div className="">
								<img src={AutomationsImage} className="border rounded bg-light mb-4 crisp-image feature-img" alt="replace this" width="100%" />
								<h4>Automated communications</h4>
								<p className="lead text-muted">In addition to your customers being able to schedule online, you will spend dramatically less time on the phone with automated appointment confirmations, tech call/sms aheads, part order status updates, invoicing and more.</p>
							</div>
							<div lg={4} className="">
								<img src="https://cdn.buttercms.com/ADWkUhUwSEOPREgxrgV3" className="border rounded bg-light mb-4  crisp-image feature-img" alt="replace this" width="100%" />
								<h4>Tech and job tracking</h4>
								<p className="lead text-muted">Customer accessible technician and job tracking, automated robocalling confirmations, and technician call aheads all add up to one thing: less work for you.</p>
							</div>
							<div lg={4} className="">
								<img src={SurveyImage} className="border rounded bg-light mb-4 feature-img" alt="replace this" width="100%" />
								<h4>Effective surveys</h4>
								<p className="lead text-muted">When a job is complete your customers can recieve an invitation to take a short survey before seeing their invoice. Valuable data about your companies performance analytics can be gained from this, as well as <b>positive review generation</b> on major sites such as Google, Yelp, and AngiesList.</p>
							</div>
						</div>
					</Container>
				</div>
				<div className="border-top border-bottom py-6 bg-light" id="online-booking">
					<Container>
						<div className="grid-2-col md-gap">
							<div className="d-flex flex-column justify-content-center">
								<h2>Online booking option gets you more clients</h2>
								<p className="lead text-muted">Modern times calls for modern measures. These days, the overwhelming majority of consumers prefer to schedule online compared to scheduling via phone or other means.</p>
								<p className="lead text-muted">Our highly integrated online scheduler allows your customers to view your live availability and schedule jobs straight into ServiceDesk, all without you lifting a finger.</p>
							</div>
							<div className="text-center">
								<img src={ConfirmImage} className="crisp-image shadow-hard border rounded mb-4" alt="replace this" width="100%" />
								<p className="text-very-muted font-wight-bold"><b>The online scheduler is customizable and can be whitelabeled to match your company's unique colors and branding.</b></p>
							</div>
						</div>

					</Container>
				</div>

				<div className="py-7">
					<div className="container">
						<div className="grid-2-col mb-4">
							<div>
								<h3>Base price</h3>
								<div><h3 className="h2">$10/mo</h3></div>
								<p className="mb-3">Immediate access to all these amazing automation features!</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Embeddable online scheduling widget</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Embeddable online technician tracking widget</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Embeddable online job widget</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Automated appointment confirmations </p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Automated surveys</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>Positive review generation</p>
								<p className="mb-3"><span className="circle-check"><FaCheck /></span>"Technician is on the way" SMS or robo-calls</p>
							</div>
							<div>
								<div>
									<h3>Additional fees</h3>
									<div>We structure our pricing to be flexible and work with all sizes of companies. The best way to do that is price everything ala-cart</div>
								</div>
								<ListGroup variant="flush" className="rounded-bottom">
									<ListGroup.Item>90&#162;/website-originated, scheduled new job request</ListGroup.Item>
									<ListGroup.Item>35&#162;/invitation-triggered first-time appointment on already-existing job</ListGroup.Item>
									<ListGroup.Item>25&#162;/invitation-triggered follow-up appointment</ListGroup.Item>
									<ListGroup.Item>15&#162;/completed confirmation resolution, plus .01/request that includes a tech-pic (if via email), or communication fee if via text-message or RoboCall</ListGroup.Item>
									<ListGroup.Item>1&#162;/month/pending job, .30 for first JobStatus view, .10 for each view after*</ListGroup.Item>
									{!hideExtraPricing &&
										<Fragment>
											<ListGroup.Item>1&#162;/tech-tracking appointment, 30&#162; for first view, 10&#162; for each view after</ListGroup.Item>

											<ListGroup.Item>30&#162;/Online Survey completed by user</ListGroup.Item>
											<ListGroup.Item>0&#162;/tech-is-on-his-way communication, plus applicable communication fee, if any</ListGroup.Item>
											<ListGroup.Item>0&#162;/post-completion communication, plus applicable communication fee, if any</ListGroup.Item>
											<ListGroup.Item>0&#162;/tech-is-on-his-way communication, plus applicable communication fee, if any</ListGroup.Item>
											<ListGroup.Item>Most communications that are servicer-side initiated may be done via email, text-message or RoboCall. Email communications have no added fee. Text-messages are 3&#162; each; RoboCalls are 9&#162; each for calls that complete to recipient, except are not charged at all in the confirmation context unless a confirmation resolution results.</ListGroup.Item>
										</Fragment>
									}
									{hideExtraPricing && <ListGroup.Item><Button variant="primary" className="btn-block btn-primary" onClick={() => this.toggleExtraPricing()}><MdKeyboardArrowDown /> Show more <MdKeyboardArrowDown /></Button></ListGroup.Item>}

								</ListGroup>
							</div>
						</div>

					</div>
				</div>
				<GetDemo />
			</div>
		)
	}
}
