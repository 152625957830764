import React, { Component } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import MetaGroup from '../../components/meta-group';
import SDHero from '../../assets/images/home-hero.png';
import { Img } from 'react-image';

class Overview extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Appliance Repair Software Overview | Rossware"
                    metaDescription="The premier software for any field service business. ServiceDesk is not a mass-market software for mere auxiliary functions, it is a customized operations software, providing the automated nuts and bolts for what you really do, as a service call-oriented office, in delivering service to your customers."
                    ogImage="%PUBLIC_URL%/logo512.png"
                    canonical="features/call-management"
                />
                <div className="py-6">
                    <Container className="">
                        <div className="grid-2-col">
                            <div className="d-flex flex-column justify-content-center align-items-left">
                                <div className="pre-text">business management software</div>
                                <h1 className="">Optimize and modernize your service business</h1>
                                <p className="lead text-muted pb-4">Set your business up for success with an all-in-one Windows based solution to manage your jobs and customers, track your team’s time and parts, and keep track of your business results.</p>
                                <div>
                                    <Link className="btn btn-primary btn-icon-right mr-2" to="/sd-order">Sign up <FaArrowRight /></Link>
                                    <Link className="btn btn-link btn-icon-right" to="/contact">Request demo <FaArrowRight /></Link>
                                </div>
                            </div>
                            <Img src={SDHero} alt="ServiceDesk on an Laptop" width="100%" className="oversize-image" loader={<img src="https://cdn.buttercms.com/sxwKwsBSnCwSGaQmj7eT" className="oversize-image loader-image" alt="ServiceDesk on an Laptop" width="100%"></img>}/>
                        </div>
                    </Container>
                </div>

                {/* CALL MANAGEMENT */}
                <div className="py-7">
                    <Container className="container-sm text-center">
                        <h2 className="mb-3">Manage your calls</h2>
                        <p className="text-muted mb-6">The heart of ServiceDesk revolves around CallSheets. These powerful digital notepads are much more than meets the eye. ServiceDesk is always behind the scenes watching for ways to help you complete your work for you. Within CallSheets as you type, auto-complete suggestions will be at the ready to fill in names, addresses, phone numbers, and more. </p>
                    </Container>
                    <Container className="">
                        <div className="grid-2-col lg-gap">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-3">Quick entry templates</h4>
                                <p className="text-muted">If you have information you enter often from sources such as high volume clients, our QuickEntry Templates will enable you to create custom templated CallSheets to autofill the data you want with quick key commands.</p>
                                <h4 className="mb-3">Validate information</h4>
                                <p className="text-muted">No more going to invalid addresses or calling invalid numbers. With the click of a button ServiceDesk will validate addresses and phone numbers with advanced validation to confirm you have good information on your customers.</p>
                                <h4 className="mb-3">Transition calls into jobs</h4>
                                <p className="text-muted">Once you have the necessary information you want on a call and its time to convert it into a job, transitioning a CallSheet into a job record is easy.</p>
                            </div>
                            <img src="https://cdn.buttercms.com/vOoiEfjcTrC1do9ka3so" width="80%" alt="Call management with Call Sheets" className="shadow rounded oversize-image"></img>
                        </div>
                    </Container>
                </div>
                {/* DISPATCH MANAGEMENT */}
                <div className="py-7 bg-light">
                    <Container className="container-md text-center">
                        <h2 className="mb-3">Schedule and dispatch</h2>
                        <p className="mb-6 text-muted">Once you've got your customer scheduled, you obviously need to create the kind of documentation that goes with performing a job -- such as printing a work order for the tech to take with him, for example.  Easy.  With just one command, a work order will automatically drop into your printer, and ServiceDesk will then type the relevant data onto it -- in whatever format, and with whatever type style you have set. ServiceDesk even numbers the work order for you. It even inserts the proper map grid reference for the street, so your technicians never again have to look such references up.</p>
                    </Container>
                    <Container className="">
                        <div className="grid-2-col lg-gap">
                            <img src="https://cdn.buttercms.com/UajLoBc4SRS9bg5HQ6qz" width="100%" alt="Call management with Call Sheets" className="shadow rounded"></img>
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-3">Dispatch map</h4>
                                <p className="text-muted">View your custom service territory on your Dispatch Map. You can see locations of jobs, who they're assigned to, technician routes, and more.</p>

                                <h4 className="mb-3">Easy scheduling</h4>
                                <p className="text-muted">Scheduling jobs can be done in a variety of ways in ServiceDesk. You can do it when transitioning a call into a job, or right from the dispatch map! You can view job statuses and your schedule from the Day Planner.</p>
                                <h4 className="mb-3">Fully automated scheduling</h4>
                                <p className="text-muted">Use powerful automation tools to automatically assign jobs to your techs for the most optimized schedule possible.</p>
                            </div>
                        </div>
                    </Container>
                </div>
                {/* JOB MANAGEMENT */}
                <div className="py-7">
                    <Container className="container-md text-center">
                        <h2 className="mb-3">Job Management</h2>
                        <p className="mb-6 text-muted">Instant lookup of every job's history and status based on no more than a snippet from name, address or telephone number. Rapid lookup based on Invoice Number, P.O. number, or even street name. Automatic generation of customer mailing lists, etc. Integrated survey system creates detailed reports regarding source of jobs (whether referral, repeat, yellow page shopper, etc.) and relative effectiveness of each ad. ServiceDesk records sale and completion of each job, compiles record of all sales, generates periodic reports showing totals, percentages in various categories, etc. Creates accounts receivable records, manages periodic dunning on past due accounts (with differentiation for major, institutional clients), aging, etc. Generates commission reports based on sales, wage reports based on built-in TimeClock functions, etc. Does not write checks or do financial accounting, but integrates easily with any separate system.</p>
                    </Container>
                    <Container className="">
                        <div className="grid-2-col lg-gap">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-3">Job statuses</h4>
                                <p className="text-muted">As a job progresses from a simple phone call to an actual job, you can view and track the statuses such as Currently Scheduled, Dispatched to Tech, Waiting for Parts, and more.</p>
                                <h4 className="mb-3">Job history</h4>
                                <p className="text-muted">Full narrative history of each job so you can see the who, what, where, and why. Both office personnel and technician's time is tracked in detail. You can see an overview of how productive your employees are with various reporting tools. </p>
                                <h4 className="mb-3">Parts</h4>
                                <p className="text-muted">Within ServiceDesk you can manage your desired inventory, your current stocked parts, and special order parts for certain jobs.</p>
                            </div>
                            <img src="https://cdn.buttercms.com/QAnRPQyjRzaFmxVOwSUz" width="80%" alt="Call management with Call Sheets" className="shadow rounded oversize-image"></img>
                        </div>
                    </Container>
                </div>
                {/* INVENTORY AND PARTS MANAGEMENT */}
                <div className="pb-7 pt-4">
                    <Container className="container-md text-center">
                        <h2 className="mb-3 text-center">Inventory and Parts</h2>
                        <p className="mb-6 text-muted">Instantly shows stock inventory on each truck and in store room. Facilitates daily restock to each truck and restock from suppliers. Facilitates and records special orders with direct, internal fax transmission to suppliers. Password-protected FundsControl tracks each item of money received, assuring no payment is credited unless actual money reaches deposit (or you know about it). Prepares deposit documents.</p>
                    </Container>
                    <Container className="">
                        <div className="grid-2-col lg-gap">
                            <div className="d-flex flex-column justify-content-center">
                                <h4 className="mb-3">Comprehensive inventory</h4>
                                <p className="text-muted">Keep a list of your desired inventory including each type of item that you intend to maintain in stock, including its description, abbreviations for the type of machine (or application) it's used on, the make, your regular or preferred customer prices, and minimum quantities you wish to maintain on each truck and in your stock room.</p>
                                <h4 className="mb-3">Unique items</h4>
                                <p className="text-muted">View your inventory list to see a unique entry for every item (or set of items in bulk) you actually have in stock at any moment in time. View the amount you paid for it, its location in stock room or on a particular truck, and how long its been in that location.</p>
                                <h4 className="mb-3">Special ordering</h4>
                                <p className="text-muted">For items you don't make a habit of keeping in your inventory, there are many methods by which parts can be specially ordered within the scope of a single job that requires it. These special order parts requests can even be made by your service technicians in the field.</p>
                            </div>
                            <img src="https://cdn.buttercms.com/ZXVlgQdpRDGAaYirfEod" alt="Call management with Call Sheets" className="shadow rounded oversize-image"></img>
                        </div>
                    </Container>
                </div>
                {/* Enterprise features */}
                <div className="py-7 bg-primary bg-image-pills">

                    <Container className="">
                        <h3 className="h2 text-white text-center mb-6">Ready to blow away the competition?</h3>
                        <Card className="mb-4">
                            <Card.Body className="p-5">
                                <div className="grid-3-col line-gap">
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">Dedicated support team</h4>
                                        <p className="mb-0 text-muted">Our dedicated Customer Support Team provide premium technical support to make sure our clients are receiving the assistance and service needed to achieve their goals.</p>
                                    </div>
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">Flexibility</h4>
                                        <p className="mb-0 text-muted">We know companies come in all shapes and sizes. We've setup our software and software addons to provide flexible pricing to fit your unique use case.</p>
                                    </div>
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">Comprehensive knowledge base</h4>
                                        <p className="mb-0 text-muted">Take advantage of our <a href="https://resources.rossware.com/">Help Center</a> that provides all the step-by-step guides and video tutorials to answer all your questions.</p>
                                    </div>
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">Role-based access control</h4>
                                        <p className="mb-0 text-muted">Easily manage the permissions for each user and block features behind master passwords, so they can only see the features, actions, and information needed to do their day-to-day job.</p>
                                    </div>
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">Always improving</h4>
                                        <p className="mb-0 text-muted">We are always improving our software based on feedback to make sure our clients always have the software that gives them the cutting edge in their industry.</p>
                                    </div>
                                    <div className="bg-white p-4">
                                        <h4 className="h5 mb-3">30+ years industry experience</h4>
                                        <p className="mb-0 text-muted">We're not just software developers. Rossware's founder ran a highly successful appliance repair company for years which resulted in the development of Rossware.</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <div className="text-center">
                            <Link className="btn btn-dark" to="/features">See all the features</Link>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default Overview;