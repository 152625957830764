import React from 'react'
import { Link } from 'react-router-dom';
import MetaGroup from '../components/meta-group';
import { Container } from 'react-bootstrap';
import DispatchLogo from '../assets/integrations/dispatchme.png';
import ServiceBenchLogo from '../assets/integrations/servicebench.png';
import SamsungLogo from '../assets/integrations/samsung.svg';
import ServiceLiveLogo from '../assets/integrations/servicelive.png';
import ServicePowerLogo from '../assets/integrations/servicepower.png';
import HeroFull from '../assets/images/home-hero.png';
// import Hero from '../components/Hero';
import { FaArrowRight } from 'react-icons/fa';
import VideoLightbox from '../components/video-lightbox';
import GetDemo from '../components/CTAs/GetDemo';
import { Img } from 'react-image';
import { setProspect } from '../helpers/cookies';

class Home extends React.Component {
  render() {
    let referrer = document.referrer;
    return (
      <div>
        <MetaGroup
          title="Rossware | Appliance Repair Software"
          metaDescription="The best field service management software tools for businesses of all sizes. Rossware builds software solutions to help field service businesses to optimize their workflow and improve their bottom line. A perfect product for Appliance Repair, HVAC repair, Garage door repair, plumbing, electricians, and more."
          ogImage="%PUBLIC_URL%/logo512.png"
          canonical=""
        />
        <div className="section">
          <Container className="">
            <div className="grid-2-col">
              <div className="d-flex flex-column justify-content-center align-items-left">
                <div className="pre-text">business management software</div>
                <h1 className="">Optimize and modernize your service business</h1>
                <p className="lead pb-4 text-muted">Set your business up for success with an all-in-one Windows based solution to manage your jobs and customers, track your team’s time and parts, and keep track of your business results.</p>
                <div>
                    <Link className="btn btn-primary btn-icon-right mr-2" to="/demo" onClick={() => setProspect(referrer, 'home_demo')}>Schedule a demo <FaArrowRight /></Link>
                    <Link className="btn btn-link btn-icon-right" to="/sd-order">Sign up <FaArrowRight /></Link>
                </div>
              </div>
              <div className="position-relative">
                <Img src={HeroFull} alt="ServiceDesk on an Laptop" width="100%" className="border rounded shadow-sm" loader={<img src="https://cdn.buttercms.com/sxwKwsBSnCwSGaQmj7eT" className="loader-image" alt="ServiceDesk on an Laptop" width="100%"></img>} />
                <VideoLightbox videoAddress="509933068" />
              </div>
            </div>
          </Container>
        </div>
        <div className="section hide-mobile bg-light">
          <div className="container">
            <h3 className="mt-0 mb-5 h6 text-uppercase text-center">Integrations and automations</h3>
            <div className="container-sm grid-5-col md-gap">
              <img src={DispatchLogo} width="100%" alt="Dispatch.me logo"></img>
              <img src={ServiceBenchLogo} width="100%" alt="ServiceBench logo"></img>
              <img src={SamsungLogo} width="100%" alt="Samsung logo"></img>
              <img src={ServiceLiveLogo} width="100%" alt="Service Live logo"></img>
              <img src={ServicePowerLogo} width="100%" alt="ServicePower logo"></img>
            </div>
          </div>
        </div>
        <div className="section">
          <Container className="grid-3-col text-center">
            <div>
              <h5>Scheduling &amp; Dispatch</h5>
              <p className="mb-5 text-muted">Say goodbye to sticky notes. Our software provides input, tracking, messaging, and associated processing for every incoming call as it transitions from a call into a scheduled job.</p>
            </div>
            <div>
              <h5>Job Management</h5>
              <p className="mb-5 text-muted">Manage your customers, keep track of current jobs, view job statuses, and customer history.</p>
            </div>
            <div>
              <h5>Map View</h5>
              <p className="mb-5 text-muted">With a custom map of your specific service territory you can see the relative geographic locations, and time, for each job, with every technician's route geographically displayed and color coded.</p>
            </div>
            <div>
              <h5>Planner View</h5>
              <p className="mb-5 text-muted">View your schedule on a planner view, similar to a calendar, to get an easy at a glance view of your technician's days.</p>
            </div>
            <div>
              <h5>Inventory Management</h5>
              <p className="mb-5 text-muted">Manage your desired inventory, your current stocked parts, see unique items in your inventory, and special order parts for certain jobs.</p>
            </div>
            <div>
              <h5>Technician Mobile App</h5>
              <p className="mb-5 text-muted">iOS and Android apps for your techs that provide them with everything they need to excel at their job and communicate with the office.</p>
            </div>
            <div>
              <h5>Estimates</h5>
              <p className="mb-5 text-muted">Easily provide estimates to your customers for work, and convert them into jobs.</p>
            </div>
            <div>
              <h5>Invoicing</h5>
              <p className="mb-5 text-muted">Save time and money with our custom digital invoices containing your business's name, logo, and colors that are sent to your customers.</p>
            </div>
            <div>
              <h5>Online booking</h5>
              <p className="mb-5 text-muted">Your customers can access an online scheduler that embeds right in your website that can show your live availability by zone.</p>
            </div>
          </Container>
        </div>

        <section className="light-tone">
          <div className="container">
            <div className="grid-2-col lg-gap">
              <div className="feature-text">
                <div className="grid-rows">
                  <h3 className="h2">Made in the industry, for the industry</h3>
                 <p className="text-muted">Rossware's core Windows based software, ServiceDesk, was built from the ground up by an Appliance Repair Company owner. With our industry experience we are able to create solutions for the problems unique to your company to elevate you above the competition.</p>
                  <Link className="btn btn-primary" to="/features/">View details</Link>
                </div>
              </div>
              <div className="widget-zone">
                <img src="https://cdn.buttercms.com/vOoiEfjcTrC1do9ka3so" width="100%" alt="Call management with Call Sheets" className="widget"></img>
              </div>
            </div>
            <div className="grid-2-col lg-gap py-6">
              <div className="widget-zone">
                <img src="https://cdn.buttercms.com/UajLoBc4SRS9bg5HQ6qz" width="100%" alt="Call management with Call Sheets" className="widget"></img>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <h3 className="h2 mb-4">Customized for your company</h3>
                <p className="text-muted">ServiceDesk includes a custom dispatch map where you can view your routes, job locations, technician locations, and more. The software also includes custom street data based on that territory for autofilling of location information automatically while you type in fields around the software.</p>
                <Link className="h5 text-dark" to="/features/">View details <FaArrowRight className="h6 mt-2" /></Link>
              </div>
              
            </div>
          </div>

          <div className="section">
            <Container>
              <div className="grid-2-col lg-gap">
                <div>
                  <h3 className="h2 mb-4">Technicians get more work done with Rossware</h3>
                  <p className="text-muted">Your techs can see their jobs, clock in and out, leave descriptions of work done, request orders for parts, schedule future jobs, invoice and take payment in the field, and more!</p>
                  <Link className="h5 text-dark" to="/features/">View details <FaArrowRight className="h6 mt-2" /></Link>
                </div>
                <div className="text-center">
                  <img src="https://cdn.buttercms.com/ssxSB9dQwGySBRYtQdgH" width="100%" alt="Appliance repair technician working on a washing machine." className="shadow-hard border rounded"></img>
                </div>
              </div>
            </Container>
          </div>
        </section>

        <GetDemo light />
      </div>

    )
  }
}
export default Home;