export function setProspect(referrer, action) {
    localStorage.setItem('user_gid', getCookie('_ga'));
    localStorage.setItem('user_date', new Date());
    localStorage.setItem('user_referrer', referrer);
    localStorage.setItem('user_action', action);
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}