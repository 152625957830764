import React, { Component } from 'react'
import DarkCallToAction from '../../components/dark-call-to-action';
import MetaGroup from '../../components/meta-group';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { Container } from 'react-bootstrap';

class SdRevenueBuilder extends Component {
   constructor(props) {
      super(props)

      this.state = {

      }
   }

   render() {
      return (
         <div>
            <MetaGroup
               title="SD-RevenueBuilder - Maintenance Agreements and Contracts"
               metaDescription="Do you have maintenance agreements or contracts? This tools is for you!"
               ogImage="%PUBLIC_URL%/logo512.png"
               canonical="sd-revenuebuilder"
            />
            <div className="py-6">
                    <Container className="container-sm">
                        <div className="text-center">
                            <h1 className="h2">Maintain service contracts and maintenance agreements</h1>
                            <p className="lead pb-4">The SD-RevenueBuilder addon app allows you to create and maintain service contracts and maintenance agreements with ease.</p>
                            <div>
                                <Link className="btn btn-primary btn-icon-right px-5" to="/contact">Contact sales <FaArrowRight /></Link>
                            </div>
                        </div>
                    </Container>
                </div>
				<div className="bg-white border-top border-bottom">
					<div className="py-7 container text-center">
						<h3 className="mb-4">Managing made easy</h3>
						<p>If you sell and manage your own service contracts, maintenance agreements, or simply want a system that reminds you to perform COD service for particular clients on a periodic basis, this product is designed to meet all such needs.  It integrates closely with the functions in ServiceDesk, but adds management of those activities that are particularly associated with periodic billing and planned service.</p>
                  <img src="https://cdn.buttercms.com/reaHcL0lRuuENCi82hOf" className="w-100 rounded shadow border" alt="SD-RevenueBuilder app helps you maintain service contracts and maintenance agreements." />
					</div>
				</div>
            <DarkCallToAction title="Just $30/mo" subtitle="Contact our sales department to get started today!" link="/contact" linkText="Contact" />
         </div>
      )
   }
}

export default SdRevenueBuilder;