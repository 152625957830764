import React, { Component } from 'react';
import MetaGroup from '../../components/meta-group';

class Disclosure extends Component {
    render() {
        return (
            <div>
                <MetaGroup
                    title="Responsible Disclosure Policy| Rossware"
                    metaDescription="This Policy details the agreement between us and the individuals who use our Websites and Applications, who are authorized to use our Services."
                    ogImage=""
                    canonical="eula"
                />
                <div className="pt-6 rounded-bottom grey-hero bg-light">
                    <h1 className="text-center">Responsible Disclosure Policy</h1>
                </div>
                <div className="">
                    <div className="container-sm pb-6">
                        <p>Rossware is providing this service to help ensure a safe and secure environment for all users.
                        If external parties find any sensitive information, potential vulnerabilities, or weaknesses, please help by responsibly disclosing it to ResponsibleDisclosure@fullsteam.com.</p>
                        <p>This policy applies to Rossware hosted applications and to any other subdomains or services associated with products. Rossware does not accept reports for vulnerabilities which solely affect marketing websites (www.rossware.com), containing no sensitive data.</p>
                        <h4>Security researchers must not:</h4>
                        <ul>
                            <li>engage in physical testing of facilities or resources,</li>
                            <li>engage in social engineering,</li>
                            <li>send unsolicited electronic mail to Rossware users, including “phishing” messages,</li>
                            <li>execute or attempt to execute “Denial of Service” or “Resource Exhaustion” attacks,</li>
                            <li>introduce malicious software,</li>
                            <li>execute automated scans or tools that could disrupt services, such as password guessing attacks, or be perceived as an attack by intrusion detection/prevention systems,</li>
                            <li>test in a manner which could degrade the operation of Rossware systems; or intentionally impair, disrupt, or disable Rossware systems,</li>
                            <li>test third-party applications, websites, or services that integrate with or link to or from Rossware systems,</li>
                            <li>delete, alter, share, retain, or destroy Rossware data, or render Rossware data inaccessible, or,</li>
                            <li>use an exploit to exfiltrate data, establish command line access, establish a persistent presence on Rossware systems, or “pivot” to other Rossware systems.</li>
                        </ul>
                        <h4>Security researchers may:</h4>
                        <ul>
                            <li>View or store Rossware nonpublic data only to the extent necessary to document the presence of a potential vulnerability.
                        Security researchers must:</li>
                            <li>cease testing and notify us immediately upon discovery of a vulnerability,</li>
                            <li>cease testing and notify us immediately upon discovery of an exposure of nonpublic data, and,</li>
                            <li>purge any stored Rossware nonpublic data upon reporting a vulnerability.</li>
                        </ul>
                        <h4>Thank you for helping to keep Rossware and our users safe!</h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default Disclosure;