import React, { Component, Fragment } from 'react';
import { Button, Card, Container, Form, Modal } from 'react-bootstrap';
import { request } from '../../helpers/request';
import { saveAs } from 'file-saver';
import moment from 'moment';


export class Downloads extends Component {
  state = {
    downloads: [],
    businessId: null,
    businessPass: null,
    chosenDownload: null,

    showCredentialsModal: false,
    wrongCredentials: false,
  }
  async componentDidMount() {
    const downloadsResponse = await request({ endpoint: 'v3/backend/getRosswareProductDownloads', parameters: { backend_key: 'rossprog' }, method: 'GET' });
    const downloads = {};
    downloadsResponse.forEach(download => {
      downloads[download.Tags.Product] = (downloads[download.Tags.Product] || []).concat([download])
    });
    this.setState({ downloads });
  }
  async processDownload(download) {
    if (download.Tags.Protect) {
      this.setState({ chosenDownload: download, showCredentialsModal: true })
    } else {
      const downloadUrlResponse = await request({ endpoint: 'v3/getRosswareUserDownloadUrl', parameters: { business_id: '777', business_password: 'support', key: download.Key }, method: 'GET' });
      saveAs(downloadUrlResponse.presignedUrl);
    }
  }
  renderDownloadProducts() {
    const { downloads } = this.state;
    return Object.keys(downloads).map((download, dex) => {
      return (
        <Card className="mb-4" key={ `download-product-${download}` }>
          <Card.Body>
            <h6>{download}</h6>
            { this.renderIndividualDownloadsPerProduct(download) }

          </Card.Body>
        </Card>
      )
    })
  }
  renderIndividualDownloadsPerProduct(product) {
    const { downloads } = this.state;
    const sorted = downloads[product].sort((a,b) => {
      if ((a?.Tags?.Ordinal > b?.Tags?.Ordinal) || (!a?.Tags?.Ordinal && b?.Tags?.Ordinal)) {
        return 1;
      } else if ((a?.Tags?.Ordinal < b?.Tags?.Ordinal) || (a?.Tags?.Ordinal && !b?.Tags?.Ordinal)) {
        return -1;
      } else {
        return 0;
      }
    })
    return sorted.map((download, dex) => {
      return <div key={ `download-${product}-${dex}` } onClick={ () => this.processDownload(download) } style={{ color: 'blue', cursor: 'pointer', padding: '5px', marginBottom: '5px' }}>{ download.Tags.Name }{ download.Tags.Version && ` -- Version ${ download.Tags.Version }` }{ ` (${ moment(download?.Tags?.Modified || download.LastModified).format("MM/DD/YYYY") })` }</div>
    })
  }
  async downloadWithCredentials() {
    const { businessId, businessPass, chosenDownload } = this.state;
    console.log(businessId, businessPass);
    const authentication = await request({ endpoint: 'v3/authenticateBusiness', parameters: { business_id: businessId, business_password: businessPass }, method: 'GET' });
    console.log(authentication);
    if (authentication.message !== 'unauthorized') {
      const downloadUrlResponse = await request({ endpoint: 'v3/getRosswareUserDownloadUrl', parameters: { business_id: '777', business_password: 'support', key: chosenDownload.Key }, method: 'GET' });
      saveAs(downloadUrlResponse.presignedUrl);
      this.setState({ wrongCredentials: false })
    } else {
      this.setState({ wrongCredentials: true })
    }
  }
  render() {
    const { showCredentialsModal, wrongCredentials, chosenDownload } = this.state;
    return (
      <Fragment>
        <Container className="container-md">
          { this.renderDownloadProducts() }
        </Container>
        <Modal
          show={ showCredentialsModal }
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={ () => this.setState({ showCredentialsModal: false, businessId: null, businessPass: null }) }
        >
          <Modal.Header>
            <Modal.Title>
              Please enter your Rossware business credentials to download this file: { `${chosenDownload?.Tags?.Product} -- ${chosenDownload?.Tags?.Name}` }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Control onChange={ (e) => this.setState({ businessId: e.target.value }) } placeholder="Business ID" type="text" style={{ marginBottom: '7px' }} />
            <Form.Control onChange={ (e) => this.setState({ businessPass: e.target.value }) } placeholder="Password" type="password" />
            { wrongCredentials && <p style={{ marginTop: '15px', marginBottom: '0px' }}>Those credentials were not recognized as valid Rossware business credentials. Please try again.</p> }
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={ () => this.downloadWithCredentials() }>Download</Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    )
  }
}

export default Downloads;

