export function validateText(value) {
  if (!value) { return false }
  if (value === 'default') { return false }
  if (typeof(value) === 'object') { return true }
  if (value && value.length > 0 && value !== 'default' && value !== '') { return true }
  return false;
}

export function validateEmail(incomingValue) {
  if (!incomingValue) { return false }
  const value = incomingValue.toLowerCase();
  if (!value) { return false }
  if (value.match(/^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,3}$/)) { return true } else { return false }
}

export function validateDate(value) {
  if (!value) { return false }
  if (value === null) { return false } else { return true }
}

export function validateTime(value) {
  if (!value) { return false }
  if (value.length > 0) { return true } else { return false }
}

export function validateAddress(value) {
  if (!value) { return false }
  if (value.length > 0) { return true } else { return false }
}

export function validateZip(value) {
  if (!value) { return false }
  if (value.match(/^\d{5}$/) || value.match(/^([A-Za-z]\d[A-Za-z][-]?\d[A-Za-z]\d)/)) {
    return true;
  } else {
    return false;
  }
}

export function validatePhone(value) {
  if (!value) { return false }
  var strippedPhone = value.replace(/\D/g,'');
  if(strippedPhone.length===10||strippedPhone.length===11){
      return true;
  }else{
      return false;
  }
}

// stupid, I know.
export function validateBoolean (bool) {
  return bool;
}

export function validatePaymentCardNumber (incomingPaymentCardNumber) {
  if (!incomingPaymentCardNumber) { return false }
  const paymentCardNumber = incomingPaymentCardNumber.replace(/\D/g,'');
  if (paymentCardNumber.length > 14 && paymentCardNumber.length < 17) { return true }
  return false;
}
export function validatePaymentCardSecurityCode (incomingPaymentCardSecurityCode) {
  if (!incomingPaymentCardSecurityCode) { return false }
  const paymentCardSecurityCode = incomingPaymentCardSecurityCode.replace(/\D/g,'');
  if (paymentCardSecurityCode.length > 2 && paymentCardSecurityCode.length < 5) { return true }
  return false;
}
export function validatePaymentCardExpiration (incomingPaymentCardExpiration) {
  if (!incomingPaymentCardExpiration) { return false }
  const paymentCardExpiration = incomingPaymentCardExpiration.replace(/\D/g,'');
  if (paymentCardExpiration.length === 4) { return true }
  return false;
}

