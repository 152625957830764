import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'

class PricingSchedule extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        return (
            <div className="container-md">
                {!this.props.isStandalone &&<h2>Addons</h2>}
                <table className="table table-bordered">
                    <tr>
                        <th></th>
                        <th>Standard pricing</th>
                        {this.props.isStandalone && <th className="text-success">Price if using RosswarePay</th>}
                    </tr>
                    {!this.props.isStandalone && <tr>
                        <td>Dedicated hands-on training</td>
                        <td><Link className="underlined" to="/contact">Contact us</Link></td>
                    </tr>}
                    {this.props.isStandalone && <tr>
                        <td>Legacy Support (updates and product support) <br /><small>SD-Support pricing scales based on how many active technicians your company has, from 1 tech to 11+.</small>
                        </td>
                        <td>$75 - $100/mo</td>
                        <td className="text-success font-weight-bolder">$40 - $65/mo</td>
                    </tr>}
                    <tr>
                        <td>SD-Mobile (technician app)</td>
                        <td>$22/user/mo <br /><small>$30/mo minimum</small></td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">$17/user/mo<br /><small>$20/mo minimum</small></td>}
                    </tr>
                    <tr>
                        <td>SD-CyberOffice (communication automation)</td>
                        <td>Usage based, <a href="https://help.rossware.com/docs/other/sd-cyberoffice/detailed-pricing" className="underlined">click here</a></td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">Same as standard</td>}
                    </tr>
                    <tr>
                        <td>DispatchLinks (ServiceBench, ServicePower, etc)</td>
                        <td>$25/mo</td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">$19/mo</td>}
                    </tr>
                    <tr>
                        <td>SD-RevenueBuilder (recurring service)</td>
                        <td>$40/mo</td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">$35/mo</td>}
                    </tr>
                    <tr>
                        <td>SD-Dealer (merchandise sales)</td>
                        <td>$35/mo</td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">$30/mo</td>}
                    </tr>
                    <tr>
                        <td>Server Hosting</td>
                        <td><Link to="/hosting" className="underlined">Tiered pricing</Link></td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">Same as standard</td>}
                    </tr>
                    <tr>
                        <td>American Home Shield Status Updater</td>
                        <td>$10/mo</td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">Same as standard</td>}
                    </tr>
                    <tr>
                        <td>SmartParts</td>
                        <td>$25/mo</td>
                        {this.props.isStandalone && <td className="text-success font-weight-bolder">Same as standard</td>}
                    </tr>
                </table>
            </div>
        )
    }
}

export default PricingSchedule