import React from 'react'
import { Helmet } from 'react-helmet';
import { Button, Col, Form, ListGroup, ListGroupItem } from 'react-bootstrap';
import { states, provinces } from '../../assets/collections/statesAndProvinces'
import { hiddenCCString, getPrice } from '../../helpers/currency';
import { Link } from 'react-router-dom';
import { request } from '../../helpers/request';
import { Alert } from 'react-bootstrap';
import {  validateText, validateEmail, validateDate, validateTime, validateAddress, validateZip, validatePhone, validateBoolean, validatePaymentCardNumber, validatePaymentCardSecurityCode, validatePaymentCardExpiration } from '../../helpers/validation';
import InputMask from 'react-input-mask';
import { Fragment } from 'react';

export default class SdOrder extends React.Component {
	state = {
    showAstiCoupon: true,
    techCount: this.props.match.params.techCount,
    orderOption: this.props.match.params.option,
		loading: true,
    step: 'about_you',
    orderNumber: null,
    stepIsValid: false,
    validationsNeeded: {
      about_you: {
        requirements: [
          { name: 'business_name', type: 'text' },
          { name: 'contact_first', type: 'text' },
          { name: 'contact_last', type: 'text' },
          { name: 'street_address', type: 'address' },
          { name: 'city', type: 'text' },
          { name: 'state_province', type: 'text' },
          { name: 'zip_postal_code', type: 'zip' },
          { name: 'phone', type: 'phone' },
          { name: 'email', type: 'email' },
        ],
      },
      agreement: {
        requirements: [
          { name: 'agreement_name', type: 'text' },
          { name: 'agreed', type: 'boolean' },
        ],
      },
      payment: {
        requirements: [
          { name: 'card_number', type: 'cardNo' },
          { name: 'cvc', type: 'cardSec' },
          { name: 'expiration', type: 'cardExp' },
          { name: 'cardholder', type: 'text' },
          { name: 'billing_zip', type: 'zip' },
        ],
      },
      finalize: {
        requirements: [
        ],
      },
      thank_you: {
        requirements: [
        ],
      },
    },
    warning: null,
    couponApplied: false,
    discountFactor: 1,
    validations: {
      text: validateText,
      email: validateEmail,
      date: validateDate,
      time: validateTime,
      address: validateAddress,
      zip: validateZip,
      phone: validatePhone,
      boolean: validateBoolean,
      cardNo: validatePaymentCardNumber,
      cardSec: validatePaymentCardSecurityCode,
      cardExp: validatePaymentCardExpiration,
    },
    formData: {
      business_name: null,
      contact_first: null,
      contact_last: null,
      country: 'us',
      street_address: null,
      city: null,
      state_province: 'default',
      zip_postal_code: null,
      phone: null,
      email: null,
      technician_quantity: this.props.match.params.techCount ? this.props.match.params.techCount : 11,
      service_territory: null,
      purchase_plan_type: this.props.match.params.option === '2' ? 'one_time' : 'monthly',
      agreed: false,
      agreement_name: null,
      card_number: null,
      cvc: null,
      expiration: null,
      cardholder: null,
      billing_zip: null,

      coupon: null,
    }
	}
  componentDidMount() {
    if (this.state.step === 'about_you') {
      this.businessNameInput.focus();
    }
  }
  validateStep = (stepOverride) => {
    const { formData, validations, validationsNeeded } = this.state;
    const step = stepOverride ? stepOverride : this.state.step;
    const stepRequirements = validationsNeeded[step].requirements;
    if (!stepRequirements) return true;
    var valid = true;
    for (var stepRequirement of stepRequirements) {
      const { name, type } = stepRequirement;
      const valueToCheck = formData[name];
      const validation = validations[type];
      if (!validation(valueToCheck)) {
        valid = false;
        break;
      }
    }
    this.setState({ stepIsValid: valid })
  }
  handleNavigation = (action) => {
    const { formData, couponApplied } = this.state;
    const {
      business_name,
      contact_first,
      contact_last,
      street_address,
      city,
      state_province,
      zip_postal_code,
      phone,
      email,
      technician_quantity,
      service_territory,
      purchase_plan_type,
      agreement_name,
      card_number,
      cvc,
      expiration,
      cardholder,
      billing_zip,
      coupon } = formData;
    function authorize () {
      return new Promise(async (resolve, reject) => {
        try {
          const parameters = {
            card_number: card_number.replace(/\D/g,''),
            cvc: cvc.replace(/\D/g,''),
            expiration: expiration.replace(/\D/g,''),
            cardholder,
            zip: billing_zip,
            amount: '10.00',
          };
          const auth = await request({ endpoint: 'v3/preAuthorizeCCforRw', parameters, method: 'POST' });
          if (auth.message.includes('Success') || auth.message.includes('DECLINED,DUPLICATE')) {
            resolve();
          } else {
            reject();
          }
        } catch (err) {
          reject();
        }
      })
    }
    function submitOrder () {
      return new Promise(async (resolve, reject) => {
        const jsonPayerString = btoa(JSON.stringify({
          name: cardholder,
          cc: card_number,
          exp: expiration,
          cvc,
          billing_zip,
        }));
        var randomstring = require("randomstring");
        const orderNumber = randomstring.generate({ length: 20 });
        try {
          await request({ endpoint: 'v3/backend/submitServiceDeskOrder', parameters: {
            order_number: orderNumber,
            business_name,
            contact_first,
            contact_last,
            street_address,
            city,
            state_province,
            zip_postal_code,
            phone,
            email,
            technician_quantity,
            service_territory,
            coupon: couponApplied ? coupon: '',
            purchase_plan_type,
            agreement_name,
            jsonPayerString,
            backend_key: '9IlHeI__SNF^51O*FLaSN__',
          }, method: 'POST' });
          resolve(orderNumber);
        } catch (err) {
          console.log(err);
        }
      })
    }

    const stepOrder = ['about_you', 'agreement', 'payment', 'finalize', 'thank_you'];
    const { step } = this.state;

    if (step === 'payment' && action === 'forward') {
      authorize().then(() => {
        this.setState({ step: stepOrder[stepOrder.indexOf(step) + 1] });
        this.validateStep(stepOrder[stepOrder.indexOf(step) + 1]);
      }).catch((err) => {
        const warning = {
          message: 'Your payment method was declined. Please confirm the details are correct, then press Continue to try again.',
          variant: 'warning'
        }
        this.setState({ warning: warning })
      })
    } else if (step==='finalize' && action === 'forward') {
      submitOrder().then((orderNumber) => {
        this.setState({ step: stepOrder[stepOrder.indexOf(step) + 1], orderNumber});
      }).catch((err) => {
        const warning = {
          message: 'Your payment method was declined. Please confirm the details are correct, then press Continue to try again.',
          variant: 'warning'
        }
        this.setState({ warning: warning })
      })
    } else if (action === 'forward') {
      this.setState({ step: stepOrder[stepOrder.indexOf(step) + 1], warning: null, });
      this.validateStep(stepOrder[stepOrder.indexOf(step) + 1]);
    } else {
      this.setState({ step: stepOrder[stepOrder.indexOf(step) - 1], warning: null });
      this.validateStep(stepOrder[stepOrder.indexOf(step) - 1]);
    }
  }
  inputChange = ({ event }) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.validateStep();
    this.setState({ formData });
  }
  checkCoupon = () => {
    const { formData } = this.state;
    const { coupon } = formData;
    const sanitized = coupon.toLowerCase().replace(/\s+/g, '');
    if (coupon && sanitized === 'portsmouth') {
      const warning = {
        message: 'Thanks for coming to see us at PSA 2023, your 15% discount has been applied!',
        variant: 'success'
      }
      this.setState({ warning: warning, couponApplied: true, discountFactor: 0.85 })
    } else {
      const warning = {
        message: 'We did not recognize your coupon code. Please check your spelling and try again.',
        variant: 'warning'
      }
      this.setState({ warning: warning })
    }
  }
  renderStateOrProvinceOptions = () => {
    const { formData } = this.state;
    if (formData.country === 'ca') {
      const provincesArray = Object.keys(provinces);
      return provincesArray.map(province => {
        return <option value={ province } key={ province }>{ provinces[province] }</option>
      })
    } else {
      const statesArray = Object.keys(states);
      return statesArray.map(state => {
        return <option value={ state } key={ state }>{ states[state] }</option>
      })
    }
  }
  renderStepIndicator () {
    const { step } = this.state;
    return (
      <div className="step-container">
        <div className={`step-indicator ${(step === 'about_you' || step === 'territory') && 'stepIndicatorStepActive'}`}>
          <div className="step-inner-top">Step 1</div>About you
        </div>
        <div className={`step-indicator ${step === 'agreement' && 'stepIndicatorStepActive'}`}>
          <div className="step-inner-top">Step 2</div>Agreement
        </div>
        <div className={`step-indicator ${step === 'payment' && 'stepIndicatorStepActive'}`}>
          <div className="step-inner-top">Step 3</div>Payment
        </div>
        <div className={`step-indicator ${step === 'finalize' && 'stepIndicatorStepActive'}`}>
          <div className="step-inner-top">Step 4</div>Finalize
        </div>
      </div>
    )
  }

  handlePricePlanClick = (planClicked) => {
    const { formData } = this.state;
    if (planClicked === 'monthly') {
      formData.purchase_plan_type = 'monthly';
    } else {
      formData.purchase_plan_type = 'one_time';
    }
    this.setState({ formData });
  }
  renderStep = () => {
    const { step, orderNumber, couponApplied, discountFactor, showAstiCoupon } = this.state;
    const { formData } = this.state;

    function payPlanDescription() {
      return `$${ getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor })}/month for at least 6 months, then at-will`;

    }

    if (step === 'about_you') {
      return (
        <Form className="mt-3">
        <div>
            <Form.Label className="form-label">Your business's name</Form.Label>
            <Form.Control defaultValue={ formData.business_name } name="business_name" ref={(input) => { this.businessNameInput = input; }} onChange={ (event) => { this.inputChange({ event }) } } placeholder="Your business" />
        </div>
        <div className="grid-2-col">
            <div>
              <Form.Label className="form-label">Your first name</Form.Label>
              <Form.Control defaultValue={ formData.contact_first } name="contact_first" onChange={ (event) => { this.inputChange({ event }) } } placeholder="e.g., John" />
            </div>
            <div>
              <Form.Label className="form-label">Last name</Form.Label>
              <Form.Control defaultValue={ formData.contact_last } name="contact_last" onChange={ (event) => { this.inputChange({ event }) } } placeholder="e.g., Smith" />
            </div>
        </div>
        <div>
          <Form.Label className="form-label">Country</Form.Label>
          <Form.Control defaultValue={ formData.country } as="select" name="country" onChange={ (event) => { this.inputChange({ event }) } }>
            <option value="us">United States</option>
            <option value="ca">Canada</option>
          </Form.Control>
        </div>
        <div className="grid-2-col">
          <div>
            <Form.Label className="form-label">Street address</Form.Label>
            <Form.Control defaultValue={ formData.street_address } name="street_address" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
          <div>
            <Form.Label className="form-label">City</Form.Label>
            <Form.Control defaultValue={ formData.city } name="city" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
        </div>
        <div className="grid-2-col">
          <div>
            <Form.Label className="form-label">{ formData.country === 'us' ? 'State' : 'Province' }</Form.Label>
            <Form.Control defaultValue={ formData.state_province } as="select" name="state_province" onChange={ (event) => { this.inputChange({ event }) } } >
                <option disabled value="default">Select</option>
                { this.renderStateOrProvinceOptions() }
            </Form.Control>
          </div>
          <div>
            <Form.Label className="form-label">{ formData.country === 'us' ? 'Zip code' : 'Postal code' }</Form.Label>
            <Form.Control defaultValue={ formData.zip_postal_code } as={ InputMask } mask={formData.country ==='ca' ? 'a9a9a9' : '99999'} name="zip_postal_code" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
        </div>
        <div className="grid-2-col">
          <div>
            <Form.Label className="form-label">Contact phone</Form.Label>
            <Form.Control defaultValue={ formData.phone } as={ InputMask } mask="(999) 999-9999" name="phone" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
          <div>
            <Form.Label className="form-label">Email address</Form.Label>
            <Form.Control defaultValue={ formData.email } name="email" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
        </div>
        <div>
          <Form.Label className="form-label">Service territory (optional)</Form.Label>
          <Form.Control defaultValue={ formData.service_territory } as="textarea" name="service_territory" placeholder={ `Short description or list of ${ formData.country ==='us' ? 'zip' : 'postal' } codes. Otherwise, we will call to inquire.` } onChange={ (event) => { this.inputChange({ event }) } } />
        </div>
        <Form.Row className="formRow mb-3">
          <Col>
            <Form.Label className="form-label">Number of technicians (determines your discounted price)</Form.Label>
            <Form.Control defaultValue={ formData.technician_quantity } as="select" name="technician_quantity" onChange={ (event) => { this.inputChange({ event }) } } >
              <option value="1">1 Tech</option>
              <option value="2">2 Techs</option>
              <option value="3">3 Techs</option>
              <option value="4">4 Techs</option>
              <option value="5">5 Techs</option>
              <option value="6">6 Techs</option>
              <option value="7">7 Techs</option>
              <option value="8">8 Techs</option>
              <option value="9">9 Techs</option>
              <option value="10">10 Techs</option>
              <option value="11">11+ Techs</option>
            </Form.Control>
          </Col>
        </Form.Row>

        <div className={`pricingPlanOption ${formData.purchase_plan_type === 'monthly' && 'pricingPlanOptionActive'}`} onClick={ () => this.handlePricePlanClick('monthly') }>
          <div className="lead text-dark mb-0 font-weight-bolder">Recurring subscription</div>
          <div className="small mb-4">After 6 month minimum commitment</div>
          <div className="lead text-dark mb-0 font-weight-bolder">${ getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor }) } per month</div>
        </div>

        { showAstiCoupon &&
        <div className="grid-2-col">
          <div>
            <Form.Label className="formLabel">Coupon code</Form.Label>
            <Form.Control disabled={ couponApplied } defaultValue='' name="coupon" onChange={ (event) => { this.inputChange({ event }) } } />
          </div>
          <div>
            <Button disabled={ formData.coupon === '' || couponApplied } onClick={ () => this.checkCoupon() } className="mt-4" variant="primary">Apply</Button>
          </div>
        </div>
        }

        </Form>
      )
    }
    if (step === 'territory') {
      return (
        <div>
          yeeee
        </div>
      )
    }
    if (step === 'agreement') {
      return (
        <div className="purchaseAgreement mt-3">


          <b>Six-month Minimum Subscription</b><br />
          Any individual, whether on the behalf of a business or him/herself, who checks the box below and completes this sign up process agrees to make six subsequent payments to Rossware Software, LLC in the amount of ${getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor })}. After the initial six payments, subscription is at-will and can be cancelled at any time for any reason.

          <br /><br />
          <b>Contractual Agreement</b><br />
          <div>
            As authorized representative for, and on behalf of { formData.business_name }, I acknowledge and accept the terms set above in Rossware's six-month minimum subscription and requirement to start a RosswarePay contract for processing card payments in the software. By continuing this ordering process, I realize I am instantiating a contract between { formData.business_name } and Rossware Software, LLC.
            I understand that, in reliance on { formData.business_name }’s promise in this contract, Rossware Software, LLC. will create custom-tailored elements to fit { formData.business_name }’s own specifically-requested territory. Rossware Software, LLC. will make a significant investment to accomplish this.
            With this understanding, { formData.business_name } agrees that it is subject to a contractual obligation to make 6 monthly payments of ${getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor })}.
            A subscription to ServiceDesk includes full access to our wonderful support and updates. { formData.business_name } agrees that, should it default in its obligation as here described, any remaining balance will escalate as due on the date of default.
          </div>
          {/* <b>One-Year Satisfaction Guarantee</b><br />
          If any purchaser of ServiceDesk has placed it into full implementation* for a period of at least 30 days, and if after this the purchaser concludes it is not the system it wishes to use (and in circumstances where the purchaser is nevertheless still continuing to need service-management software) . . . if these contingencies arise, the purchaser will be released from further obligation under the purchase contract and any such monies as have been paid in purchase will be promptly refunded by Rossware Software, LLC. to the purchaser.
          <br />
          <div className="text-muted mt-2">
            *Full implementation is defined as using each of ServiceDesk’s basic-and-core-level operational elements in the direct operation of your business. It does not mean needing to use advanced-level elements or any of Rossware's supplemental systems.
          </div>
          <br /><br />
          <b>Contractual Agreement</b><br />
          { formData.purchase_plan_type === 'monthly' &&
          <div>
            As authorized representative for, and on behalf of { formData.business_name }, I acknowledge and accept the terms in Rossware's Satisfaction Guarantee (described above). By continuing this ordering process, I realize I am instantiating a purchase contract between { formData.business_name } and Rossware Software, LLC.
            I understand that, in reliance on { formData.business_name }’s promise in this contract, Rossware Software, LLC. will create custom-tailored elements to fit { formData.business_name }’s own specifically-requested territory. Rossware Software, LLC. will make a significant investment to accomplish this.
            With this understanding, { formData.business_name } agrees that, except and unless terms in the Satisfaction Guarantee override, it is subject to a contractual obligation to make 24 monthly payments of ${getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor })}.
            Rossware's 24-month purchase plan includes 24 months of support and updates. { formData.business_name }'s 24 monthly payments of ${getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor })} will total an agreed purchase price of ${ 24 * getPrice({ plan: 'monthly', numTechs: formData.technician_quantity, discountFactor: discountFactor }) }. { formData.business_name } agrees that, should it default in its obligation as here described, any remaining balance will escalate as due on the date of default.
          </div>
          }
          { formData.purchase_plan_type !== 'monthly' &&
          <div>
            As authorized representative for, and on behalf of { formData.business_name }, I acknowledge and accept the terms in Rossware's Satisfaction Guarantee (described above). By continuing this ordering process, I realize I am instantiating a purchase contract between { formData.business_name } and Rossware Software, LLC.
            I understand that, in reliance on { formData.business_name }’s promise in this contract, Rossware Software, LLC. will create custom-tailored elements to fit { formData.business_name }’s own specifically-requested territory. Rossware Software, LLC. will make a significant investment to accomplish this.

            With this understanding, { formData.business_name } agrees that, except and unless terms in the Satisfaction Guarantee override, it is subject to a contractual obligation to make 1 payment of ${ getPrice({ plan: 'one_time', numTechs: formData.technician_quantity, discountFactor: discountFactor }) }.
          </div>
          } */}
          <br /><br />
          Checking the "I agree" checkbox and entering your name signifies you have read, agree to and understand the above terms in their entirety.
          <br />
          Checking the "I agree" checkbox and entering your name will legally bind you to the agreements above stated.
          <br /><br />
          <Form.Label className="form-label">Full Name</Form.Label>
          <Form.Control defaultValue={ formData.agreement_name } name="agreement_name" onChange={ (event) => { this.inputChange({ event }) } } />
          <Form.Group controlId="formBasicCheckbox" className="mt-2">
            <Form.Check onChange={ (event) => { this.agreementOnChange() } } checked={ formData.agreed } name="agreed" className="form-label" type="checkbox" label="I agree" />
          </Form.Group>
        </div>
      )
    }
    if (step === 'payment') {
      return (
        <Form className="mt-3">
        <Form.Row className="formRow">
          <Col>
            <Form.Label className="form-label">Full name on card</Form.Label>
            <Form.Control defaultValue={ formData.cardholder } name="cardholder" onChange={ (event) => { this.inputChange({ event }) } } />
          </Col>
          <Col>
            <Form.Label className="form-label">{ formData.country === 'us' ? 'Zip code' : 'Postal code' }</Form.Label>
            <Form.Control defaultValue={ formData.billing_zip } as={ InputMask } mask={formData.country ==='ca' ? 'a9a9a9' : '99999'} name="billing_zip" onChange={ (event) => { this.inputChange({ event }) } } />
          </Col>
        </Form.Row>
        <Form.Row className="formRow">
          <Col>
            <Form.Label className="form-label">Card number</Form.Label>
            <Form.Control defaultValue={ formData.card_number } as={ InputMask } mask='9999999999999999' maskChar=" " name="card_number" onChange={ (event) => { this.inputChange({ event }) } } />
          </Col>
        </Form.Row>
        <Form.Row className="formRow">
          <Col>
            <Form.Label className="form-label">Expiration date</Form.Label>
            <Form.Control defaultValue={ formData.expiration } as={ InputMask } mask='99/99' name="expiration" onChange={ (event) => { this.inputChange({ event }) } } />
          </Col>
          <Col>
            <Form.Label className="form-label">Security code</Form.Label>
            <Form.Control defaultValue={ formData.cvc } as={ InputMask } mask='9999' maskChar=" " name="cvc" onChange={ (event) => { this.inputChange({ event }) } } />
          </Col>
        </Form.Row>
        </Form>
      )
    }
    if (step === 'finalize') {
      return (
        <Form className="mt-3 purchaseSummary">
        <Form.Row>
        <Col>
          <ListGroup>
              <ListGroupItem variant="light"><b>ServiceDesk Software Purchase</b></ListGroupItem>
              <ListGroupItem>{ payPlanDescription() }</ListGroupItem>
              <ListGroupItem>Agreement E-signed by { formData.agreement_name }</ListGroupItem>
          </ListGroup>
          <ListGroup className="mt-2">
              <ListGroupItem variant="light"><b>Business Details</b></ListGroupItem>
              <ListGroupItem>Business Name: { formData.business_name }</ListGroupItem>
              <ListGroupItem>Business Contact: { `${formData.contact_first} ${formData.contact_last}` }</ListGroupItem>
              <ListGroupItem>Business Address: { `${formData.street_address} ${formData.city}, ${formData.state_province} ${formData.zip_postal_code}` }</ListGroupItem>
              <ListGroupItem>Contact Phone: { formData.phone }</ListGroupItem>
              <ListGroupItem>Contact Email: { formData.email }</ListGroupItem>
          </ListGroup>
          <ListGroup className="mt-2">
              <ListGroupItem variant="light"><b>Payment Details</b></ListGroupItem>
              <ListGroupItem>Payer: { formData.cardholder }</ListGroupItem>
              <ListGroupItem>Billing Zip Code: { formData.billing_zip }</ListGroupItem>
              <ListGroupItem>Credit/Debit Card #: { hiddenCCString(formData.card_number) }</ListGroupItem>
          </ListGroup>
        </Col>
        </Form.Row>
        </Form>
      )
    }
    if (step === 'thank_you') {
      return (
        <div>
          <h2>Order placed!</h2>
          <hr />
          <p>Thank you for your order! We'll be starting work on it soon. As the process continues, you can <a href={ `http://getservicedesk.com/tracker.php?order=${orderNumber}` }>track your order</a> using the link we sent to your email. In the meantime, please don't hesitate to <Link to="/contact">reach out</Link> if you have any questions.</p>
        </div>
      )
    }
  }
  renderNavigation() {
    const { step, stepIsValid } = this.state;

    // forward only
    if (['about_you'].includes(step)) {
      return (
        <div className="orderFormNav">
          <Button onClick={ () => this.handleNavigation('forward') } disabled={ !!!stepIsValid } variant="primary" className="mt-3">
            Continue
          </Button>
        </div>
      )
    }
    // forward and back
    if (['agreement', 'payment'].includes(step)) {
      return (
        <div className="orderFormNav">
          <Button onClick={ () => this.handleNavigation('back') } variant="light" className="mt-3">
            Go Back
          </Button>
          <Button onClick={ () => this.handleNavigation('forward') } disabled={ !!!stepIsValid } variant="primary" className="mt-3">
            Continue
          </Button>
        </div>
      )
    }
    //finalize and back
    if (['finalize'].includes(step)) {
      return (
        <div className="orderFormNav">
          <Button onClick={ () => this.handleNavigation('back') } variant="light" className="mt-3">
            Go Back
          </Button>
          <Button onClick={ () => this.handleNavigation('forward') } disabled={ !!!stepIsValid } variant="primary" className="mt-3">
            Submit Order
          </Button>
        </div>
      )
    }

  }
  agreementOnChange = () => {
    const { formData } = this.state;
    formData.agreed = !!!formData.agreed;
    this.setState({ formData });
    this.validateStep();
  }

  renderWarning() {
    const { warning } = this.state;
    if (warning) {
      return <div><Alert className="mt-3" variant={ warning.variant }>{ warning.message }</Alert></div>
    }
    // return <div>yee</div>
  }

	render() {
    const { step } = this.state;
		return (
			<div className="bg-white">
        <Helmet>
          <meta name="fragment" content="!" />
          <title>ServiceDesk Sign Up - Rossware Software, LLC.</title>
          <meta name="fragment" content="!"></meta>
          <meta name="description" content="Order ServiceDesk now! Enter your information in the form to submit your order." />
          <meta property="og:title" content="Order ServiceDesk - Rossware Software, LLC." />
          <meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
        </Helmet>
				<div className="bg-white py-5">
          { step !== 'thank_you' &&
            <Fragment>
              <div className="container-sm">
                <h2 className="text-center">ServiceDesk Sign Up</h2>
                <p className="text-center mb-5">After signing up, please expect 2-3 business days before receiving your installation link via email. Visit our <Link to="/pricing">pricing</Link> page for more information about included support and addons.</p>
              </div>
            </Fragment>
          }
          <div className="container-sm">
            { this.renderStepIndicator() }
            { this.renderStep() }
            { this.renderWarning() }
            { this.renderNavigation() }
          </div>
				</div>

			</div>
		)
	}
}
