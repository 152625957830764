import React from 'react';
import { Link } from "react-router-dom";
import FacebookIcon from "../assets/svg/icons/facebook.svg";
import TwitterIcon from "../assets/svg/icons/twitter-alt.svg";
import YoutubeIcon from "../assets/svg/icons/youtube.svg";

export default class Footer extends React.Component {
	render() {
		return (
			<div className="section footer-section">
				<div className="footer-row">
					<div className="container">
						<div className="footer-main-grid">
							<div className="footer-link-menu">
								<div className="footer-title-text">Product</div>
								<Link className="footer-link" to="/" exact="true">Overview</Link>
								<Link className="footer-link" to="/features">Features</Link>
								<Link className="footer-link" to="/integrations">Integrations</Link>
								<Link className="footer-link" to="/pricing">Pricing</Link>
								<Link className="footer-link" to="/demo">Schedule a demo</Link>
							</div>
							<div className="footer-link-menu">
								<div className="footer-title-text">Solutions</div>
								<Link className="footer-link" to="/sd-mobile">Technician app</Link>
								<Link className="footer-link" to="/sd-cyberoffice">Online scheduling</Link>
								<Link className="footer-link" to="/sd-revenuebuilder">Recurring Service</Link>
								<Link className="footer-link" to="/sd-dealer">Over the counter sales</Link>
								<Link className="footer-link" to="/hosting">Server hosting</Link>
							</div>
							<div className="footer-link-menu">
								<div className="footer-title-text">Resources</div>
								<a className="footer-link" href="https://help.rossware.com">Help center</a>
								<Link className="footer-link" to="/connect">Connect for Support</Link>
								<Link className="footer-link" to="/contact">Contact us</Link>
								<a className="footer-link" target="_blank" rel="noreferrer" href="https://rossware.betteruptime.com">Platform status</a>
							</div>
							<div className="footer-link-menu">
								<div className="footer-title-text">Company</div>
								<a className="footer-link" href="tel:800-353-4101">800 353 4101</a>
								<a className="footer-link" href="mailto:hello@rossware.net">hello@rossware.net</a>
								<Link className="footer-link" to="/about">About us</Link>
								<a className="footer-link" href="https://blog.rossware.com">Rossware Blog</a>
								<Link className="footer-link" to="/faq">FAQs</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-row border-top">
					<div className="container">
						<div className="h1">How can we help? <Link to="/contact" className="text-primary underline underline-offset">Contact us</Link></div>
					</div>
				</div>
				<div className="footer-row border-top">
					<div className="container">
						<div className="footer-social-list">
							<a className="footer-social-circle" href="https://www.facebook.com/RosswareInc"><img src={FacebookIcon} alt="Social icon" /></a>
							<a className="footer-social-circle" href="https://x.com/rosswareinc"><img src={TwitterIcon} alt="Social icon" /></a>
							<a className="footer-social-circle" href="https://www.youtube.com/user/RosswareInc"><img src={YoutubeIcon} alt="Social icon" /></a>
						</div>
						<div className="footer-bottom">
							<div>
								<Link to="/terms" className="footer-link footer-bottom-link">Terms of Use</Link>
								<Link to="/disclosure" className="footer-link footer-bottom-link">Responsible Disclosure Policy</Link>
								<Link to="/privacy-policy" className="footer-link footer-bottom-link">Privacy Policy</Link>
							</div>
							<span className="footer-link footer-bottom-link">&#169; {new Date().getFullYear()}, Fullsteam Software Holdings LLC. All Rights Reserved.</span>
						</div>
					</div>
				</div>
			</div>


		)
	}
}