import React from 'react'
import DarkCallToAction from '../components/dark-call-to-action'
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import LegacyImg from '../assets/images/about/glade-legacy.png';
import KentonImg from '../assets/images/about/kenton.png';
import KyloImg from '../assets/images/about/kylo-ren.png';
import TeamImg from '../assets/images/about/team.png';
import Masonry from 'react-masonry-css';
import TradeShowImg from '../assets/images/about/tradeshow.png';
import AwardsImg from '../assets/images/about/awards.jpg';

export default class About extends React.Component {
	render() {
		return (
			<div>
				<Helmet>
					<meta name="fragment" content="!" />
					<title>About us | Rossware Software, LLC.</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="We are dedicated to helping the appliance repair industry excel with leading software solutions." />
					<meta property="og:title" content="About us | Rossware Software, LLC." />
					<meta property="og:image" content="%PUBLIC_URL%/logo512.png" />
				</Helmet>
				<section className="section">
					<div className="container-md">
						<div className="d-flex justify-content-center align-items-center flex-column">
							<h1 className="">About us</h1>
							<div className="about-grid">
								<div className="text-large text-center">At Rossware, we're here to empower appliance repair businesses with top-notch software solutions and excellent support. As Industry leaders, we provide tools that boost efficiency, streamline operations, and improve customer satisfaction. Our team is dedicated to helping you succeed, and we're here to support you every step of the way.</div>
							</div>
						</div>
					</div>
				</section>
				<section className="light-tone">
					<div className="container">
						<div>
							
						</div>
					</div>
				</section>
				<div className="container-md py-6">
					<Container>
						<Masonry
							breakpointCols={3}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column">
							<img src={LegacyImg} alt="Glade at a Tradeshow" className="rounded shadow" />
							<img src={AwardsImg} alt="Rossware's awards" className="rounded shadow" />
							<img src={KentonImg} alt="Kenton showing off a gift from a client" className="rounded shadow" />
							<img src={KyloImg} alt="Aaron dressed up for halloween" className="rounded shadow" />
							<img src={TeamImg} alt="The early Rossware team" className="rounded shadow" />
							<img src={TradeShowImg} alt="Rossware at a tradeshow" className="rounded shadow" />
						</Masonry>
					</Container>
				</div>
				<DarkCallToAction title="Interested in joining our team?" subtitle="Reach out to us!" link="/contact" linkText="Contact" />
			</div>
		);
	}
}
