import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class SiteMap extends Component {
    render() {
        return (
            <div>
                <Helmet>
					<title>Sitemap for Rossware Software, LLC.</title>
					<meta name="fragment" content="!"></meta>
					<meta name="description" content="Sitemap for Rossware Software, LLC." />
					<meta property="og:title" content="Sitemap for Rossware Software, LLC." />
					<meta property="og:image" href="%PUBLIC_URL%/logo512.png" />
				</Helmet>
                <div className="pt-5 pb-4 bg-light">
                    <Container>
                        <h1>Rossware.com Sitemap</h1>
                    </Container>
                </div>
                <div className="pt-5 pb-4">
                    <Container>
                        <div className="grid-4-col lg-gap">
                            <div>
                                <h3 className="h4">Product</h3>
                                <Link to="/overview" className="text-dark my-3 small d-block">Overview</Link>
                                <Link to="/features" className="text-dark my-3 small d-block">Features</Link>
                                <Link to="/sd-mobile" className="text-dark my-3 small d-block">Technician app</Link>
                                <Link to="/integrations" className="text-dark my-3 small d-block">Integrations</Link>
                            </div>
                            <div>
                                <h3 className="h4">Solutions</h3>
                                <Link to="/sd-cyberoffice" className="text-dark my-3 small d-block">Online scheduling</Link>
                                <Link to="/hosting" className="text-dark my-3 small d-block">Server hosting</Link>
                                <Link to="/sd-revenuebuilder" className="text-dark my-3 small d-block">Recurring service</Link>
                                <Link to="/sd-dealer" className="text-dark my-3 small d-block">Merchandise sales</Link>
                            </div>
                            <div>
                                <h3 className="h4">Resources</h3>
                                <Link to="/contact" className="text-dark my-3 small d-block">Contact</Link>
                                <Link to="/connect" className="text-dark my-3 small d-block">Connect for support</Link>
                                <a href="https://resources.rossware.com" className="text-dark my-3 small d-block">Knowledge base</a>
                                <Link to="/pricing" className="text-dark my-3 small d-block">Pricing</Link>
                            </div>
                            <div>
                                <h3 className="h4">Company</h3>
                                <Link to="/about" className="text-dark my-3 small d-block">Our Story</Link>
                                <Link to="/blog" className="text-dark my-3 small d-block">Case Studies</Link>
                                <Link to="/blog" className="text-dark my-3 small d-block">Blog</Link>
                                <Link to="/faq" className="text-dark my-3 small d-block">FAQs</Link>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export default SiteMap;