import React, { Component } from 'react';
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { Redirect } from 'react-router-dom';

export default class WrongRemote extends Component {
  state = {
    counter: 10,
  }
  componentDidMount = () => {
    setInterval(this.updateCounter, 1000)
  }
  updateCounter = () => {
    let { counter } = this.state;
    counter--;
    this.setState({ counter: counter })
  }
  render() {
    const { counter } = this.state;
    if (counter === 0) {
      return <Redirect to="/connect" />
    }
    return (
    <div className="container-sm py-7 text-center">
      <h1><BsFillExclamationCircleFill /></h1>
      <p>We've changed our remote support platform! If you've arrived here, it's because you haven't yet updated ServiceDesk or SD-Mobile to reflect that change. We're redirecting you to our online page for remote support in</p>
      <h1>{ counter }</h1>
      <p>seconds. Or you can <a href="https://resources.rossware.com/resources/downloads">update ServiceDesk here.</a></p>
      </div>
    )
  }
}
