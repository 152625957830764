import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

class Styles extends Component {
    render() {
        return (
            <Container>
                <h1>H1 Heading</h1>
                <h2>H2 Heading</h2>
                <h3>H3 Heading</h3>
                <h4>H4 Heading</h4>
                <h5>H5 Heading</h5>
                <h6>H6 Heading</h6>
            </Container>
        );
    }
}

export default Styles;