import React, { Component } from 'react';
import { IoClose } from "react-icons/io5";

class FaqItem extends Component {
    state = {
        active: false
    }
    render() {
        return (
            <div>
                <div className="expand-item" onClick={() => this.setState({active: !this.state.active})}>
                    <div className="expand-title">{this.props.title}</div>
                    <div className={`expand-body ${this.state.active && 'expanded'}`}>
                        <div>
                            {this.props.children}
                        </div>
                        <IoClose />
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqItem;