import React, { Component } from 'react';
import MetaGroup from '../../components/meta-group';
import Faq from '../../components/faq';

class ScheduleDemo extends Component {
  state = {
    to: 'support@rossware.net',
    subject: 'I want to schedule a demo',
    first: '',
    last: '',
    company: '',
    email: '',
    phone: '',
  }
  
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  render() {
    return (
      <div>
        <MetaGroup
          title="Schedule a demo - Rossware Software, LLC."
          metaDescription="Reach out to us, we're here to help you. You can contact us by phone, email, or live chat."
          ogImage="%PUBLIC_URL%/logo512.png"
          canonical="contact"
        />
        <section>
          <div className="container grid-2-col md-gap">
            <div>
              <h1 className="mb-3">Ready to see more of Rossware?</h1>
              <p className="lead">Schedule a demo with one of our experts to find out how Rossware can improve the way you do business.</p>
            </div>
            <div>
              <div className="demo-form">
                <div className="calendly-inline-widget" data-url="https://calendly.com/dylan-rossware/servicedesk-demo?hide_event_type_details=1&hide_gdpr_banner=1"></div>
              </div>
            </div>
          </div>
        </section>
        <Faq />
      </div>
    );
  }
}

export default ScheduleDemo;