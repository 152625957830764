import React, { Component } from 'react';
import { Alert, Form, Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { FaArrowRight } from 'react-icons/fa';
import { sendEmail } from '../../actions/send-email';
import MetaGroup from '../../components/meta-group';
import { validateEmail } from '../../helpers/validation';
import InputMask from 'react-input-mask';
import RwPayFaq from '../../components/rwpay-faq';

class RosswarePaySignup extends Component {
	state = {
		to: 'pay@rossware.net',
		subject: 'RosswarePay signup',
		first: '',
		last: '',
		company: '',
		email: '',
		phone: '',
		files: [],
		sendSuccess: false,
		captchaGood: false,
	}


	onChangeInput = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	// handleFileSelect = (event) => { //for file adding later
	// 	console.log(event.target.files);

	// 	this.setState({ files: event.target.files });
	// }

	onChangeCaptcha = (event) => {
		this.setState({ captchaGood: true });
	}

	async handleSubmit() {
		this.setState({ sendSuccess: true });
		const { to, from, first, last, phone, company } = this.state;
		let body = `Please signup ${company} for RosswarePay \n Name: ${first} ${last} \nPhone number: ${phone}`;
		let subject = `RosswarePay signup for ${company}`;
		console.log({to, from, subject, body});
		// let attachments = this.state.files;
		await Promise.all([
			sendEmail({ to, from, subject, body })
		]);
	}

	render() {
		const { from, subject, captchaGood, sendSuccess } = this.state;
		var disabled = true;
		if (validateEmail(from) && subject && captchaGood) {
			disabled = false;
		}
		return (
			<div className="container-md py-6">
				<MetaGroup
					title="RosswarePay signup - Rossware Software, LLC."
					metaDescription="With RosswarePay you get competitive rates, world-class support, the newest terminals, and the very best integration with Rossware software."
					ogImage="%PUBLIC_URL%/logo512.png"
					canonical="contact"
				/>
				<div className="grid-2-col md-gap mb-6">
					<div>
						<h1 className="mb-3">Get started with RosswarePay</h1>
						<p className="lead">Get competitive rates, world-class support, the newest terminals, and the very best integration with Rossware software.</p>
					</div>
					<div>
						<div className="demo-form">
							<h2 className="h4">Fill out this form to get started</h2>
							{sendSuccess &&
								<Alert variant="success" className="py-4">
									<Alert.Heading>Thank you!</Alert.Heading>
									<hr />
									<div>We'll be reaching out to you soon with the next steps.</div>
								</Alert>
							}
							{!sendSuccess &&
								<Form className="text-left">
									<div className="form-group grid-2-col">
										<div>
											<label>First name <code>*</code></label>
											<Form.Control type="text" name="first" onChange={this.onChangeInput} placeholder="e.g., John" />
										</div>
										<div>
											<label>Last name <code>*</code></label>
											<Form.Control type="text" name="last" onChange={this.onChangeInput} placeholder="e.g., Smith" />
										</div>

									</div>
									<div className="form-group">
										<label>Company name &amp; four digit UserID <code>*</code></label>
										<Form.Control type="text" name="company" onChange={this.onChangeInput} placeholder="Your company / UserID" />
									</div>
									<div className="form-group">
										<label>Email <code>*</code></label>
										<Form.Control type="email" name="from" onChange={this.onChangeInput} placeholder="you@company.com" />
									</div>
									<div className="form-group position-relative">
										<label>Phone <code>*</code></label>
										<Form.Control as={InputMask} mask="999-999-9999" type="tel" name="phone" onChange={this.onChangeInput} placeholder="000-000-0000"></Form.Control>
									</div>
									{/* <div className="form-group position-relative">
										<label>Statements (please only include 2-3)</label>
										<Form.Control type="file" multiple name="attachments" className="" onChange={this.handleFileSelect} accept="application/pdf, image/png, image/gif, image/jpeg, image/jpg"></Form.Control>
										<div className="small text-muted mt-3"><b>Why statements?</b> Including statements enables us to provide the best apples to apples comparison and ensure we're beating the effective rate you're currently paying. Statements submitted in this form will remain confidential with the company and only be used for purposes of providing rates.</div>
									</div> */}

									<div className="float-left mb-3">
										<ReCAPTCHA
											size="normal"
											sitekey="6LcjVFsUAAAAAAT0mrnR8ONYHMxXbX5a37OGH8LU"
											onChange={this.onChangeCaptcha}
										/>
									</div>
									<Button disabled={disabled} className="btn-block btn-icon-right" onClick={() => this.handleSubmit()}>Get started <FaArrowRight /></Button>
								</Form>
							}
						</div>
					</div>
				</div>
				<RwPayFaq />
			</div>
		);
	}
}

export default RosswarePaySignup;