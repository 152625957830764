import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FaqItem from './faq-item';

class Faq extends Component {
    state = {
        active: false
    }
    render() {
        return (
            <div className="section light-tone">
                <div className="container grid-2-col lg-gap">
                    <div className="d-flex justify-content-center align-items-left flex-column">
                        <h2>Frequently asked questions</h2>
                        <p>If you can't find the answer you're looking for here, don't hesitate to reach out to our team via our contact page.</p>
                        <Link to="/contact" className="btn btn-primary">Contact us</Link>
                    </div>
                    <div>
                        <FaqItem title="What platform does Rossware run on?">Our main core application runs exclusively on any Windows operating system. If you don't have a Windows PC, or want "cloud" capability, we recommend hosting our software on a Windows Server (which we can provide!). Our technician mobile application runs on Windows, iOS, or Android.</FaqItem>
                        <FaqItem title="Can I view my schedule on a calendar?">Yes! ServiceDesk, our main core application, has a comprehensive Day Planner view to see and schedule jobs.</FaqItem>
                        <FaqItem title="Do you integrate with Quickbooks?">Though we do not directly integrate into Quickbooks, we do provide data exports for Quickbooks.</FaqItem>
                        <FaqItem title="Is Rossware a cloud application?">ServiceDesk is a Windows based desktop application that depends on the Windows file structure to operate. This has many advantages such as speed, your ownership of your own data, and security. Though it's not a cloud app by default, you can use it as a cloud app with a simple <Link to="/hosting">Windows server</Link> so you can access your ServiceDesk from anywhere in the world!</FaqItem>
                        <FaqItem title="Can Rossware optimize my route?">Yes! Optimize your routes either one at a time, or intiate advanced whole roster optimization powered by AI that takes into account technicians, jobs, timeframes, technician skillsets, and even when each individual lunch break should be to near perfectly route all of your day's jobs. Using this feature is optional and incurs a charge each usage. Please <Link to="/contact">contact sales</Link> to learn more.</FaqItem>
                        <FaqItem title="Can ServiceDesk integrate with warranty dispatchers?">Yes! Automate information flow both ways between the warranty companies and third parties you work with the most. Integrate with ServiceBench, ServiceLive, Dispatch.me, Service Power, LG, Samsung, and pull them directly from emails automatically.</FaqItem>
                    </div>
                </div>
            </div>
        );
    }
}

export default Faq;