import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { IoClose } from "react-icons/io5";

class RwPayFaq extends Component {

    render() {
        return (
            <Container className="container-sm py-5">
                <div className="text-center">
                    <h2>Questions &amp; answers</h2>
                    <p className="lead text-muted mb-5">Can’t find the answer here? <Link to="/contact">Contact support</Link></p>
                </div>
                <Accordion preExpanded="1">
                    <AccordionItem uuid="1">
                        <AccordionItemHeading>
                            <AccordionItemButton>Why did Rossware create RosswarePay?<IoClose className="accordion__plus" /></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>We heard from countless distraught clients describing their struggles working with existing processor integrations. Many were displeased with the high rates and hidden fees they were paying. Others found the devices unreliable and cumbersome to get working properly. Creating our own payment solution allowed us to remedy these problems for our clients and offer exciting new features we couldn’t before!</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>What rates are offered?<IoClose className="accordion__plus" /></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>The rates with RosswarePay vary company to company and it largely depends on volume or what your rate is with your current processor. The best way to figure out your rate is email a few months processor statements to <a href="mailto:pay@rossware.net">pay@rossware.net</a></p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>What terminals are offered with RosswarePay?<IoClose className="accordion__plus" /></AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>We currently offer two fantastic terminals from the leader in the payments hardware space, MagTek. The iDynamo 6 is a plug-in reader available for both iOS and Android capable of charging via swipe, EMV-chip, tap-to-pay and NFC-based means like ApplePay and AndroidPay. The tDynamo is a Bluetooth reader compatible with Windows, Android and iOS capable of charging via swipe, EMV-chip, tap-to-pay and NFC-based means like ApplePay and AndroidPay.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </Container>
        );
    }
}

export default RwPayFaq;