import { submitHubspotForm } from "./hubspot";
import { sendEmail } from "./send-email";

const portalId = "24147837";

const demoRequestFormId = "158ca5b3-9add-428b-b51c-cbfbebcb8e35";
const standardContactFormId = "af96d6be-5514-4508-b206-98a481a46b95";

export async function submitDemoRequestForm({ to, from, first, last, phone, company }) {
  const emailBody =`Please schedule a demo for \nCompany: ${company}\n Name: ${first} ${last} \nPhone number: ${phone}`;
  const emailSubject = `${company} wants to schedule a demo`;
  const emailResponse = await sendEmail({ to, from, subject: emailSubject, body: emailBody, body_is_html: true })

  const formSubmitResponse = await submitHubspotForm({
    portal_id: portalId,
    form_id: demoRequestFormId,
    form_data: {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": from
        },
        {
          "objectTypeId": "0-1",
          "name": "firstname",
          "value": first
        },
        {
          "objectTypeId": "0-1",
          "name": "lastname",
          "value": last
        },
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": company
        },
        {
          "objectTypeId": "0-1",
          "name": "phone",
          "value": phone
        },
      ]
    }
  })

  return { emailResponse, formSubmitResponse };
}

export async function submitContactForm({ to, from, subject, body }) {
  const emailResponse = await sendEmail({ to, from, subject, body, body_is_html: true })

  const formSubmitResponse = await submitHubspotForm({
    portal_id: portalId,
    form_id: standardContactFormId,
    form_data: {
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": from
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": body
        },
        {
          "objectTypeId": "0-1",
          "name": "subject",
          "value": subject
        },
      ]
    }
  })

  return { emailResponse, formSubmitResponse };
}