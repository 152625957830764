import React from 'react'
import { Helmet } from 'react-helmet';

class MetaGroup extends React.Component {
	render() {
		return (
			<Helmet>
                <title>{this.props.title}</title>
                <meta name="fragment" content="!"></meta>
                <meta name="description" content={this.props.metaDescription}/>
                <meta property="og:title" content={this.props.title} />
                <meta property="og:description" content={this.props.metaDescription} />
                <meta property="og:image" content={this.props.ogImage} />
                <link rel="canonical" href={'https://rossware.com/' + this.props.canonical} />
            </Helmet>
		)
	}
}
export default MetaGroup;