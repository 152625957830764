import { encodeObject } from "../helpers/request";

export async function submitHubspotForm({ portal_id, form_id, form_data }) {
  return fetch(`${process.env.REACT_APP_ROSSWARE_API_BASE_URL}/v3/backend/submitHubspotForm${encodeObject({
    portal_id,
    form_id,
    backend_key: process.env.REACT_APP_EMAIL_KEY,
  })}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "form_data": form_data,
      }),
    }
  )
    .then((response) => response.json())
    .then((data) => { return data });
}