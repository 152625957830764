import React from 'react';
import ModalVideo from 'react-modal-video'
import { MdPlayArrow } from 'react-icons/md';
import { Fragment } from 'react';

export default class VideoLightbox extends React.Component {
	constructor() {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}

	openModal() {
		this.setState({ isOpen: true })
	}

	render() {
		return (
			<Fragment>
				<ModalVideo channel="vimeo" autoplay="true" isOpen={this.state.isOpen} videoId={this.props.videoAddress} onClose={() => this.setState({ isOpen: false })} />
				<div className="play-button" onClick={this.openModal}><MdPlayArrow /></div>
			</Fragment>
		)
	}
}