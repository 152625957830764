import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

class Loading extends React.Component {
	render() {
		return (
			<div className="d-flex flex-column justify-content-center align-items-center loader-wrapper p-6">
					<Spinner animation="border" className="text-black-25 loader-main" />
					<div animation="border" className="text-black-25 loader-background" />
			</div>
		)
	}
}
export default Loading;