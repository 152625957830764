function decimalsAndCommas (incomingDollarAmount) {
	const dollarAmount = incomingDollarAmount.toString();
	const parts = dollarAmount.split('.');
	let dollarParts = [];
	for (let i = 0; i < parts[0].length / 3; i++) {
		dollarParts.push(parts[0].substring(parts[0].length - ((i + 1) * 3), parts[0].length - (i * 3)));
	}
	const dollarPartsReversed = dollarParts.reverse();
	let dollars = dollarPartsReversed.join(',');
	if (dollars.length === 0) {
		dollars = '0';
	}
	let cents = parts[1] ? parts[1] : '';
	if (cents.length > 2) {
		cents = cents.substring(0,2);
	}
	while (cents.length < 2) {
		cents = cents.concat('0');
	}
	return `${dollars}.${cents}`;
}

export function hiddenCCString (cc) {
	var stringArr = cc.split('');
	var returnArr = [];
	for (let dex = 0; dex < stringArr.length; dex++) {
		if (dex < stringArr.length - 4) {
			returnArr.push('*')
		} else {
			returnArr.push(stringArr[dex])
		}
	}
	return returnArr.join('');
}


export function getPrice({plan, numTechs, discountFactor}) {
	if (!discountFactor) {
		discountFactor = 1;
	}
	const discount = (11 - numTechs) * (plan === 'monthly' ? 17.5 : 300);
	const price = discountFactor * (plan === 'monthly' ? 325 - discount : 6000 - discount);
	return decimalsAndCommas(price);
}