import { useCallback, useEffect, useState } from "react";
import { Alert, Container, Spinner, Table } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { request } from "../../helpers/request";
import moment from "moment";

export default function PublicDevTasks() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTasks();
  }, []);

  async function getTasks() {
    const tasks = await request({ endpoint: 'v3/publicNotionTasks', method: 'GET' });
    setTasks(tasks);
    setLoading(false);
  }

  const renderTasksTable = useCallback(() => {
    if (loading) return (
      <tr>
        <td colSpan={5} style={{ textAlign: 'center' }}>
          <Spinner animation="border" ></Spinner>
        </td>
      </tr>
    );

    const statusColors = {
      'Gathering Info': {
        backgroundColor: 'grey',
        foregroundColor: 'white',
      },
      'In Review': {
        backgroundColor: 'grey',
        foregroundColor: 'white',
      },
      'Accepted': {
        backgroundColor: 'green',
        foregroundColor: 'white',
      },
      'Short List': {
        backgroundColor: '#E49B0F',
        foregroundColor: 'white',
      },
      'Soft Rejected': {
        backgroundColor: 'orange',
        foregroundColor: 'white',
      },
      'In Progress': {
        backgroundColor: 'blue',
        foregroundColor: 'white',
      },
      'Pending Release': {
        backgroundColor: 'purple',
        foregroundColor: 'white',
      },
      'Done': {
        backgroundColor: 'green',
        foregroundColor: 'white',
      },
      'Rejected': {
        backgroundColor: 'red',
        foregroundColor: 'white',
      },
      'Redundant + OK': {
        backgroundColor: 'green',
        foregroundColor: 'white',
      },
    }

    function renderStatusBadge(status) {
      return <div style={{ backgroundColor: statusColors?.[status?.name]?.backgroundColor, color: statusColors?.[status?.name]?.foregroundColor, padding: '10px', borderRadius: '5px', display: 'inline' }}>{ status?.name }</div>
    }

    return tasks.map(task => {
      return (
        <tr>
          <td>{ task?.properties?.Summary?.title?.[0]?.plain_text }</td>
          <td>{ (task?.properties?.["Application(s)"]?.multi_select.map(item => item?.name) || []).join(', ') }</td>
          <td>{ task?.properties?.Type?.select?.name }</td>
          <td style={{ minWidth: '120px' }}>{ moment(task?.properties?.["Created time"]?.created_time).format('MM-DD-YYYY') }</td>
          <td style={{ minWidth: '160px' }}>{ renderStatusBadge(task?.properties?.Status?.status) }</td>

        </tr>
      )
    })
  }, [tasks, loading]);

  return (
    <div>
      <Helmet>
        <title>Rossware Development Tasks</title>
        <meta name="fragment" content="!"></meta>
        <meta name="description" content="SD-Mobile is a powerful app designed to fulfill any need your service technicians may have in the field. Job information is automatically uploaded from ServiceDesk to the tech's mobile device." />
        <meta property="og:title" content="Field Service Technician Mobile App - SD-Mobile" />
        <meta property="og:image" content="path/to/image.jpg" />
      </Helmet>

      <div className="py-6">
        <Container className="">
          <h1 className="h2">Rossware Development Tasks</h1>

          <Alert variant="warning">
            This list is not intended to be a comprehensive list of all development tasks, but a selected sub-set of tasks to provide transparency into our development process.
          </Alert>
          <Table style={{ fontSize: '10pt' }} striped bordered hover>
            <thead>
              <tr>
                <th>Summary</th>
                <th>Involved Applications</th>
                <th>Type</th>
                <th>Created</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              { renderTasksTable() }
            </tbody>
          </Table>
        </Container>
      </div>
    </div>
  )
}