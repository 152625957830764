import React, { Component } from 'react'
import { BsExclamationCircleFill, BsFillCheckCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom';

export default class GmailStatus extends Component {
  render() {
    let success = this.props.match.params.success === 'true';
    return (
      <div>
        <div className="container">
          <div className="py-7 d-flex justify-content-center align-items-center flex-column">
            {success ? <BsFillCheckCircleFill className="h1 text-success" /> : <BsExclamationCircleFill className="h1 text-danger" />}
            {success ? <h1 className="mb-3 h2">Gmail authorized</h1> : <h1 className="mb-3 h2">Gmail not authorized</h1>}
            {success ? <h2 className="h4">Please close this window and return to ServiceDesk</h2> : <h2 className="h4">Please <Link to="/contact">contact Rossware for support</Link></h2>}
          </div>
        </div>
      </div>
    )
  }
}
